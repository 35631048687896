import { addColorClass, removeColorClass } from "../../cb/colorClasses"
import { loginOverlayVisible } from "../../cb/ui/loginOverlay"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { isNotLoggedIn } from "../auth"
import { isScrollDownNoticeActive } from "../featureFlagUtil"
import { EmojiSelectionModal } from "../theatermodelib/emojiSelectionModal"
import { i18n } from "../translation"
import { standardEmojiRequest } from "../userActionEvents"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { ChatTabContainer } from "./chatTabContainer"
import { DraggableCanvasWindow } from "./draggableCanvasWindow"
import { resetPureChatHideTimeouts } from "./pureChatUtil"
import { WindowKey } from "./windowKey"
import { WindowPositionerAnchors } from "./windowPositioner"
import type { DraggableCanvas } from "./draggableCanvas"
import type { IVideoModeChangeNotification } from "../videoModeHandler"

export class DraggableCanvasChatWindow extends DraggableCanvasWindow {
    chatTabContainer: ChatTabContainer
    emojiSelectionModal: EmojiSelectionModal | undefined
    private scrollDownOnResizeStop: boolean | undefined

    constructor(canvas: DraggableCanvas) {
        const chatTabContainer = new ChatTabContainer()
        super(canvas, chatTabContainer, {
            windowKey: WindowKey.Chat,
            minWidth: 150,
            minHeight: isScrollDownNoticeActive() ? 152 : 100,
            defaultWidth: 400,
            defaultHeight: 250,
            defaultAnchors: [WindowPositionerAnchors.Right, WindowPositionerAnchors.Bottom],
            resizeable: true,
            handles: [chatTabContainer.getTabRow().element],
        })

        addColorClass(this.element, "draggableCanvasChatWindow")
        addColorClass(this.element, "hasDarkBackground")
        removeColorClass(this.innerDiv, "innerDiv")
        addColorClass(this.innerDiv, "chatInnerDiv")

        chatTabContainer.chatTab.chatTabContents.formSubmitted.listen(() => {
            this.blur()
        })

        addEventListenerPoly("blur", chatTabContainer.chatTab.chatTabContents.customInputField.element, () => {
            if (canvas.hoveredWindow !== this || loginOverlayVisible()) {
                canvas.focusedWindow = undefined
                canvas.focusChanged.fire(undefined)
            }
        })

        addEventListenerPoly("mouseleave", this.element, () => {
            canvas.hoveredWindow = undefined
            canvas.windowHover.fire(undefined)
        })

        let videoMode: VideoMode
        videoModeHandler.changeVideoMode.listen((videoModeChangeNotification: IVideoModeChangeNotification) => {
            videoMode = videoModeChangeNotification.currentMode
            if (this.emojiSelectionModal !== undefined && videoMode === VideoMode.Split) {
                this.emojiSelectionModal.hide()
            }
        })

        standardEmojiRequest.listen((clickedElement: HTMLElement) => {
            if (isNotLoggedIn(`${i18n.loggedInForFeatureClickOkToLogin}`)) {
                return
            }
            if (videoMode === VideoMode.Split) {
                return
            }
            if (this.emojiSelectionModal === undefined) {
                this.emojiSelectionModal = new EmojiSelectionModal(() => this.chatTabContainer.getCurrentTab().getChatInputField())
            }
            this.emojiSelectionModal.show(clickedElement)
        })

        this.chatTabContainer = chatTabContainer
    }

    protected setDraggingOrResizing(isDraggingOrResizing: boolean): void {
        super.setDraggingOrResizing(isDraggingOrResizing)
        if (isDraggingOrResizing) {
            this.scrollDownOnResizeStop = isDraggingOrResizing && !this.chatTabContainer.chatTab.chatTabContents.isScrolledUp()
        } else {
            if (this.scrollDownOnResizeStop === true) {
                this.chatTabContainer.chatTab.chatTabContents.scrollToBottom()
            }
            this.scrollDownOnResizeStop = undefined
        }
    }

    public setPureMode(): void {
        const isSelectingEmoji = this.emojiSelectionModal?.isShown() === true

        if (isSelectingEmoji) {
            return
        }


        if (this.chatTabContainer.chatTab.chatTabContents.messageListWrapper.clientWidth) {
            this.chatTabContainer.chatTab.chatTabContents.messageListWrapper.style.width = `${this.chatTabContainer.chatTab.chatTabContents.messageListWrapper.clientWidth}px`
        }
        this.chatTabContainer.chatTab.chatTabContents.messageListWrapper.classList.add("noScrollbar")
        resetPureChatHideTimeouts([...this.chatTabContainer.chatTab.chatTabContents.messageList.children])
        this.chatTabContainer.chatTab.scrollToBottom()
        this.element.classList.add("pureChat")
        this.chatTabContainer.getTabRow().element.style.opacity = "0"
        this.innerDiv.style.backgroundColor = "initial"
        this.innerDiv.style.borderColor = "transparent"
        this.closeButton.style.opacity = "0"
    }

    public setInputMode(): void {
        this.chatTabContainer.chatTab.chatTabContents.messageListWrapper.style.width = "100%"
        this.chatTabContainer.chatTab.chatTabContents.messageListWrapper.classList.remove("noScrollbar")
        this.chatTabContainer.chatTab.scrollToBottom()
        this.element.classList.remove("pureChat")
        this.chatTabContainer.getTabRow().element.style.opacity = "1"
        this.innerDiv.style.backgroundColor = ""
        this.innerDiv.style.borderColor = ""
        this.closeButton.style.opacity = "1"
    }
}

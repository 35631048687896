import { isRoomRoomlistSpaActive } from "@multimediallc/cb-roomlist-prefetch"
import { iOSVersion, isPerformanceNowSupported } from "@multimediallc/web-utils/modernizr"
import { AutoPlayMetric } from "./player/videoMetrics"
import { documentVisibilityChange, isDocumentVisible } from "./windowUtils"
import type { CanAutoplayResult } from "../../@types/chaturbate"

const canAutoplay = window["canAutoplay"]

// This was moved out of cbModernizr to avoid a Google Closure Compiler bug
// (https://github.com/google/closure-compiler/pull/2641). Importing videoMetrics in cbModernizr created an import cycle.
// Refactoring videoMetrics to avoid the import cycle triggered the bug. Upgrading to a new version of the compiler
// broke tshandler.
export function canVideoAutoplayInline(): Promise<boolean> {
    const metric = new AutoPlayMetric()
    return new Promise((resolve) => {
        let hiddenStart = 0
        const onVisible = (isVisible: boolean) => {
            if (isVisible) {
                canAutoplayCheck(true)
            }
        }
        const canAutoplayCheck = (waited?: boolean) => {
            let hiddenDelay = 0
            if (waited === true) {
                documentVisibilityChange.removeListener(onVisible)
            }
            if (isPerformanceNowSupported() && hiddenStart !== 0) {
                hiddenDelay = window.performance.now() - hiddenStart
            }
            // short curcuit check for ios 9 and lower
            const ios = iOSVersion()
            if (ios !== undefined && ios < 10) {
                metric.send(false, hiddenDelay)
                resolve(false)
            }

            // if canAutoplay is not loaded then HLS is probably not supported either
            if (canAutoplay === undefined) {
                const msg = "canAutoplay library is undefined. Resolving autoplay to true"
                warn(msg)
                metric.send(true, hiddenDelay, new Error(msg))
                resolve(true)
                return
            }

            let canAutoplayVideo: Promise<CanAutoplayResult>

            if (isRoomRoomlistSpaActive() && window["canAutoplayPrefetchPromise"]) {
                canAutoplayVideo = window["canAutoplayPrefetchPromise"]
            } else {
                canAutoplayVideo = canAutoplay.video({
                    inline: true,
                    muted: true,
                    timeout: 1000,
                    baseUrl: STATIC_URL_ROOT,
                })
            }

            canAutoplayVideo.then(result => {
                metric.send(result.result, hiddenDelay, result.error)
                resolve(result.result)
            }).catch(err => {
                metric.send(false, hiddenDelay, err)
                resolve(false)
            })
        }
        if (isDocumentVisible()) {
            canAutoplayCheck()
        } else {
            if (isPerformanceNowSupported()) {
                hiddenStart = window.performance.now()
            }
            documentVisibilityChange.listen(onVisible)
        }
    })
}

import { addColorClass, colorClass } from "../../cb/colorClasses"
import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { RoomUsers } from "../../cb/roomUsers"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { Component } from "../defui/component"
import { EventRouter } from "../events"
import { isReactUserListActive } from "../featureFlagUtil"
import { addPageAction } from "../newrelic"
import { i18n } from "../translation"
import { UsernameLabel } from "../usernameLabel"
import type { ReactComponent } from "../../cb/components/ReactRegistry"
import type { IUserInfo } from "../messageInterfaces"

function createUsernameLabel(user: IUserInfo): UsernameLabel {
    return new UsernameLabel(user, undefined, true)
}

export class UserList extends Component {
    userCountUpdate = new EventRouter<number>("UserListWindow:userCountUpdate")
    private activeReactUserList = isReactUserListActive()
    private userListContentComponent: ReactComponent

    constructor() {
        super()

        this.element.id = "users-list"
        this.element.style.padding = "0 6px"
        this.element.style.boxSizing = "border-box"
        this.element.style.position = ""
        this.element.style.fontSize = "12px"

        if (this.activeReactUserList) {
            const UserListContentClass = ReactComponentRegistry.get("UserListContent")
            this.userListContentComponent = new UserListContentClass({
                roomUsers: [],
                createContextMenu: (user: IUserInfo, element: HTMLElement) => {
                    addPageAction("OpenUserContextMenu", { "username": user.username })
                    UsernameLabel.createUserContextMenu(user, element)
                },
                refreshFunc: () => {
                    this.refresh()
                },
                anonCount: 0,
            }, this.element)
            this.element.classList.add("react")  // Used in UserListContent.scss
        } else {
            this.createLoadingDiv()
        }
        this.refresh()
    }

    private createLoadingDiv(): void {
        const loadingDiv = document.createElement("div")
        addColorClass(loadingDiv, colorClass.defaultTooltipColor)
        loadingDiv.innerText = `${i18n.loadingUserListText}...`
        loadingDiv.style.margin = "6px 0"
        this.element.appendChild(loadingDiv)
    }

    private createRefreshUsersLink(): void {
        const refreshDiv = document.createElement("div")
        addColorClass(refreshDiv, colorClass.defaultTooltipColor)
        refreshDiv.style.margin = "6px 0"
        const refreshSpan = document.createElement("span")
        refreshSpan.innerText = i18n.refreshUserListText
        refreshSpan.id = "refresh-userlist"
        refreshSpan.style.cursor = "pointer"
        addEventListenerPoly("click", refreshSpan, ev => {
            this.removeAllDOMChildren()
            this.createLoadingDiv()
            this.refresh()
            ev.preventDefault()
        })
        addEventListenerPoly("mouseenter", refreshSpan, ev => {
            refreshSpan.style.textDecoration = "underline"
        })
        addEventListenerPoly("mouseleave", refreshSpan, ev => {
            refreshSpan.style.textDecoration = ""
        })
        refreshDiv.appendChild(refreshSpan)
        this.element.appendChild(refreshDiv)
    }

    public clear(count: number): void {
        this.userCountUpdate.fire(count)
        if (this.activeReactUserList) {
            this.userListContentComponent.update({ idShowLoading: Math.random() })
        } else {
            this.removeAllDOMChildren()
            this.createLoadingDiv()
            this.repositionChildren()
        }
    }

    refresh(): void {
        RoomUsers.getInstance().fetchRoomUsers().then((roomUsersInfo) => {
            this.userCountUpdate.fire(roomUsersInfo.totalCount)
            if (this.activeReactUserList) {
                this.userListContentComponent.update({
                    roomUsers: roomUsersInfo.roomUsers,
                    idShowUsers: Math.random(),
                    anonCount: roomUsersInfo.anonCount,
                })
            } else {
                this.removeAllDOMChildren()
                this.createRefreshUsersLink()
                for (const user of roomUsersInfo.roomUsers) {
                    const usernameDiv = document.createElement("div")
                    usernameDiv.style.width = "auto"
                    usernameDiv.style.margin = "2px 0"
                    usernameDiv.style.whiteSpace = "nowrap"
                    usernameDiv.style.overflow = "hidden"
                    usernameDiv.style.maxWidth = "100%"
                    usernameDiv.style.textOverflow = "ellipsis"
                    usernameDiv.appendChild(createUsernameLabel(user).element)
                    this.element.appendChild(usernameDiv)
                }
                const anonUsersDiv = document.createElement("div")
                addColorClass(anonUsersDiv, colorClass.defaultTooltipColor)
                anonUsersDiv.innerText = `+${roomUsersInfo.anonCount} anonymous user${roomUsersInfo.anonCount === 1 ? "" : "s"}`
                anonUsersDiv.style.width = "100%"
                anonUsersDiv.style.margin = "6px 0"
                anonUsersDiv.style.whiteSpace = "nowrap"
                this.element.appendChild(anonUsersDiv)
                this.repositionChildren()
            }
        }).catch((err) => {
            error("Error retrieving user list", err)
        })
    }

    repositionChildren(): void {
        super.repositionChildren()
    }
}

import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { normalizeResource } from "../api"
import {
    addPhotoSetClickedPageAction,
    bioInfoSectionHeadLength,
    getBioContentPromise,
    sanitizeUserCreatedContent,
}
    from "../bioContent"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { i18n } from "../translation"
import { safeWindowOpen } from "../windowUtils"
import { loadRoomRequest } from "./userActionEvents"
import type {
    IBioParsed,
    IBioPhotoset,
    IBioSocialMedia,
    IInfoSection,
    IMainContent, 
} from "../bioContent"
import type { IRoomContext } from "../context"

// region Constants declarations
const previewWidth = 150
const previewHeight = 100
// endregion

// region Helpers
// Main DOM creation function. The work is split into 4 sections that returns an HTMLDivElement with the content
function generateContent(bio: IBioParsed): IMainContent {
    const main = document.createElement("div")
    main.dataset.testid = "bio-content"
    main.style.fontSize = "14px"
    const info = generateInfoSection(bio.infoSection)
    main.appendChild(info)
    let socialMedia: HTMLDivElement | undefined
    if (bio.socialMedias.length > 0) {
        socialMedia = generateSocialMediaSection(bio.socialMedias, bio.infoSection.username)
        main.appendChild(socialMedia)
    }
    let photoVideo: HTMLDivElement | undefined
    if (bio.photoSets.length > 0) {
        photoVideo = generatePhotoVideoSection(bio.photoSets, bio.infoSection.username)
        main.appendChild(photoVideo)
    }
    let aboutMe: HTMLDivElement | undefined
    if (bio.aboutMe !== "") {
        aboutMe = generateUserContentSection(bio.aboutMe, `${i18n.aboutMeText}:`)
        main.appendChild(aboutMe)
    }
    let wishList: HTMLDivElement | undefined
    if (bio.wishList !== "") {
        wishList = generateUserContentSection(bio.wishList, `${i18n.wishListText}:`)
        main.appendChild(wishList)
    }
    return {
        main: main,
        infoSection: info,
        socialMediaSection: socialMedia,
        photoVideoSection: photoVideo,
        userContentSection: aboutMe,
        wishListSection: wishList,
    }
}

function generateUserContentSection(htmlString: string, sectionTitle: string): HTMLDivElement {
    const contentContainer = document.createElement("div")
    contentContainer.innerHTML = htmlString // eslint-disable-line @multimediallc/no-inner-html
    const main = document.createElement("div")
    main.style.paddingTop = "5px"
    const title = document.createElement("span")
    title.innerText = sectionTitle
    title.style.color = "#0c6a93"
    title.style.padding = "5px"
    title.style.display = "inline"
    title.style.verticalAlign = "top"
    main.appendChild(title)
    const contentHolder = document.createElement("div")
    contentHolder.style.padding = "0 10px"
    contentHolder.style.display = "inline-block"
    sanitizeUserCreatedContent(contentContainer, contentContainer, true)
    if (contentContainer.children.length !== 0) {
        const userContent = contentContainer.children[0] as HTMLElement
        userContent.style.display = "unset"
    }
    contentHolder.appendChild(contentContainer)
    main.appendChild(contentHolder)
    return main
}

function generatePhotoVideoSection(photosets: IBioPhotoset[], username: string): HTMLDivElement {
    const generatePhotoset = (photoset: IBioPhotoset) => {
        const ps = document.createElement("div")
        ps.style.padding = "10px"
        ps.style.position = "relative"
        ps.style.display = "inline-block"
        ps.style.height = "100px"
        ps.title = photoset.name
        ps.onmouseenter = () => {
            ps.style.cursor = "pointer"
            title.style.textDecoration = "underline"
        }
        ps.onmouseleave = () => {
            ps.style.cursor = "default"
            title.style.textDecoration = "none"
        }
        ps.onclick = () => {
            const height = 520
            const width = 803
            const left = (screen.width / 2 - width / 2).toString()
            const top = (screen.height / 2 - height / 2).toString()
            const options = `resizable,dependent,scrollbars,height=${height},width=${width},top=${top},left=${left}`
            const url = `/photo_videos/photoset/detail/${username}/${photoset.id}`
            addPhotoSetClickedPageAction(photoset, "BioTab")
            safeWindowOpen(url, undefined, options)
            return false
        }
        const preview = document.createElement("img")
        preview.src = photoset.coverUrl
        preview.width = previewWidth
        preview.height = previewHeight
        ps.appendChild(preview)
        if (!photoset.userCanAccess) {
            const locked = document.createElement("img")
            locked.src = `${STATIC_URL_ROOT}images/locked_rectangle4.png`
            locked.width = previewWidth
            locked.height = previewHeight
            locked.style.top = "10px"
            locked.style.left = "10px"
            locked.style.position = "absolute"
            locked.style.border = "none"
            ps.appendChild(locked)
        }
        if (photoset.isVideo) {
            const videoIcon = document.createElement("img")
            videoIcon.src = `${STATIC_URL_ROOT}images/play3.png`
            videoIcon.height = 20
            videoIcon.style.position = "absolute"
            videoIcon.style.top = "10px"
            videoIcon.style.left = "10px"
            ps.appendChild(videoIcon)
        }
        if (photoset.labelText !== "") {
            const tokens = document.createElement("span")
            tokens.innerText = photoset.labelText
            tokens.style.backgroundColor = photoset.labelColor
            tokens.style.position = "absolute"
            tokens.style.top = "92px"
            tokens.style.right = "15px"
            tokens.style.fontSize = "10px"
            tokens.style.padding = "2px"
            ps.appendChild(tokens)
        }

        const title = document.createElement("div")
        title.innerText = photoset.name
        title.style.fontSize = "12px"
        title.style.position = "absolute"
        title.style.top = "111px"
        title.style.left = "0px"
        title.style.paddingLeft = "13px"
        title.style.color = "#0c6a93"
        title.style.maxWidth = "145px"
        title.style.whiteSpace = "nowrap"
        title.style.textOverflow = "ellipsis"
        title.style.overflow = "hidden"
        ps.appendChild(title)
        return ps
    }
    const main = document.createElement("div")
    main.style.position = "relative"
    main.style.paddingTop = "5px"
    main.style.paddingBottom = "25px"
    const title = document.createElement("span")
    title.innerText = `${i18n.photosAndVideosText}:`
    title.style.color = "#0c6a93"
    title.style.padding = "5px"
    main.appendChild(title)
    const psContainer = document.createElement("div")
    psContainer.style.paddingLeft = "5px"
    for (const photo of photosets) {
        psContainer.appendChild(generatePhotoset(photo))
    }
    main.appendChild(psContainer)
    return main
}

function generateSocialMediaSection(socialMedias: IBioSocialMedia[], username: string): HTMLDivElement {
    const generateSocialMedia = (socialMedia: IBioSocialMedia) => {
        const sm = document.createElement("div")
        sm.style.padding = "10px"
        sm.style.position = "relative"
        sm.style.display = "inline-block"
        sm.style.height = "100px"
        sm.title = socialMedia.titleName
        sm.onmouseenter = () => {
            sm.style.cursor = "pointer"
            title.style.textDecoration = "underline"
        }
        sm.onmouseleave = () => {
            sm.style.cursor = "default"
            title.style.textDecoration = "none"
        }
        sm.onclick = () => {
            const height = 520
            const width = 803
            const left = (screen.width / 2 - width / 2).toString()
            const top = (screen.height / 2 - height / 2).toString()
            if (socialMedia.popup) {
                const options = `resizable,dependent,scrollbars,height=${height},width=${width},top=${top},left=${left}`
                safeWindowOpen(socialMedia.link, undefined, options)
            } else {
                safeWindowOpen(socialMedia.link, "_blank", "")
            }
            return false
        }
        const preview = document.createElement("img")
        preview.src = socialMedia.imageUrl
        preview.width = previewWidth
        preview.height = previewHeight
        sm.appendChild(preview)
        if (socialMedia.labelText !== "") {
            const tokens = document.createElement("span")
            tokens.innerText = socialMedia.labelText
            tokens.style.backgroundColor = socialMedia.labelColor
            tokens.style.position = "absolute"
            tokens.style.top = "92px"
            tokens.style.right = "15px"
            tokens.style.fontSize = "10px"
            tokens.style.padding = "2px"
            sm.appendChild(tokens)
        }

        const title = document.createElement("div")
        title.innerText = socialMedia.titleName
        title.style.fontSize = "12px"
        title.style.position = "absolute"
        title.style.top = "111px"
        title.style.left = "0px"
        title.style.paddingLeft = "13px"
        title.style.color = "#0c6a93"
        title.style.maxWidth = "145px"
        title.style.whiteSpace = "nowrap"
        title.style.textOverflow = "ellipsis"
        title.style.overflow = "hidden"
        sm.appendChild(title)
        return sm
    }
    const main = document.createElement("div")
    main.style.position = "relative"
    main.style.paddingTop = "5px"
    main.style.paddingBottom = "25px"
    const title = document.createElement("span")
    title.innerText = `${i18n.socialMediaText}:`
    title.style.color = "#0c6a93"
    title.style.padding = "5px"
    main.appendChild(title)
    const smContainer = document.createElement("div")
    smContainer.style.paddingLeft = "5px"
    for (const socialMedia of socialMedias) {
        smContainer.appendChild(generateSocialMedia(socialMedia))
    }
    main.appendChild(smContainer)
    return main
}

// eslint-disable-next-line complexity
function generateInfoSection(info: IInfoSection): HTMLDivElement {
    const generateHeader = (title: string) => {
        const main = document.createElement("div")
        main.style.position = "relative"
        main.style.paddingLeft = "5px"
        main.style.display = "inline"
        const span = document.createElement("span")
        span.style.color = "#0c6a93"
        span.innerText = title
        main.appendChild(span)
        return main
    }
    const generateValue = (value: string, testid: string) => {
        const main = document.createElement("div")
        main.style.position = "absolute"
        main.style.paddingRight = "5px"
        main.style.display = "inline"
        main.style.left = `${bioInfoSectionHeadLength}px`
        main.style.overflow = "hidden"
        main.style.textOverflow = "ellipsis"
        main.style.whiteSpace = "nowrap"
        const span = document.createElement("span")
        span.style.whiteSpace = "nowrap"
        span.innerText = value
        span.dataset.testid = testid
        main.appendChild(span)
        return main
    }
    const generateRow = (header: string, val: string, testid: string) => {
        const row = document.createElement("div")
        const head = generateHeader(header)
        const value = generateValue(val, testid)
        row.style.paddingTop = "5px"
        row.appendChild(head)
        row.appendChild(value)
        return row
    }

    const main = document.createElement("div")
    const capsName = info.username.charAt(0).toUpperCase() + info.username.slice(1)
    if (info.roomStatus === "public") {
        const userLink = document.createElement("a")
        userLink.href = normalizeResource(`./?${info.username}`)
        userLink.text = `View ${capsName}'s Cam`
        userLink.style.position = "relative"
        userLink.style.padding = "5px 5px 5px 0"
        userLink.style.textAlign = "right"
        userLink.style.display = "inline-block"
        userLink.style.width = "100%"
        userLink.style.fontSize = "14px"
        userLink.style.textDecoration = "none"
        userLink.onmouseenter = () => {
            userLink.style.textDecoration = "underline"
        }
        userLink.onmouseleave = () => {
            userLink.style.textDecoration = "none"
        }
        addEventListenerPoly("click", userLink, (e: MouseEvent) => {
            e.preventDefault()
            loadRoomRequest.fire(info.username)
        })
        main.appendChild(userLink)
    }

    // generate only key value pairs if there is a value to display
    if (info.realName !== "") {
        main.appendChild(generateRow(`${i18n.realNameText}:`, info.realName, "real-name"))
    }
    if (!isNaN(info.followersCount)) {
        main.appendChild(generateRow(`${i18n.followersText}:`, info.followersCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), "follower-count"))
    }
    if (info.sex !== "") {
        if (info.sex === "Couple") {
            main.appendChild(generateRow(`${i18n.sexTextCouple}:`, `${info.sex.charAt(0).toUpperCase()}${info.sex.substring(1)}`, "gender-value"))
        } else {
            main.appendChild(generateRow(`${i18n.sexText}:`, `${info.sex.charAt(0).toUpperCase()}${info.sex.substring(1)}`, "gender-value"))
        }
    }
    if (info.displayBirthday !== "") {
        main.appendChild(generateRow(`${i18n.birthdayText}:`, info.displayBirthday, "birthday-value"))
    }
    if (!isNaN(info.displayAge)) {
        main.appendChild(generateRow(`${i18n.ageText}:`, info.displayAge.toString(), "age-value"))
    }
    if (info.bodyType !== "") {
        main.appendChild(generateRow(`${i18n.bodyTypeText}:`, info.bodyType, "body-type-value"))
    }
    if (info.bodyDecorations !== "") {
        main.appendChild(generateRow(`${i18n.bodyDecorationsText}:`, info.bodyDecorations, "body-decorations-value"))
    }
    if (info.interestedIn !== "") {
        const interested = JSON.parse(info.interestedIn).join(", ")
        if (interested !== "") {
            main.appendChild(generateRow(`${i18n.interstedInText}:`, interested, "interested-in-value"))
        }
    }
    if (info.location !== "") {
        main.appendChild(generateRow(`${i18n.locationText}:`, info.location, "broadcaster-location"))
    }
    if (info.lastBroadcast !== undefined) {
        main.appendChild(generateRow(`${i18n.lastBroadcastText}:`, info.lastBroadcast, "last-broadcast-value"))
    }
    if (info.languages !== undefined && info.languages !=="") {
        main.appendChild(generateRow(`${i18n.langaugesSpokenText}:`, info.languages, "spoken-languages-value"))
    }
    if (info.smokeDrink !== "") {
        main.appendChild(generateRow(`${i18n.smokeDrinkText}:`, info.smokeDrink, "smoke-drink-value"))
    }
    return main
}

function generateNoBioContent(): IMainContent {
    const d = document.createElement("div")
    d.style.position = "absolute"
    d.style.height = "100%"
    d.style.width = "100%"
    d.style.top = "0"
    d.style.left = "0"
    d.style.display = "block"
    const s = document.createElement("span")
    s.innerText = i18n.bioUnavailableText
    s.style.display = "block"
    s.style.padding = "10px"
    d.appendChild(s)
    return { main: d }
}

// endregion

export class BioContent extends Component {
    private content?: IMainContent
    private roomLoadedListener: (context: IRoomContext) => void

    constructor(username: string) {
        super()
        this.element.style.color = "#000"
        this.loadContent(getBioContentPromise(username))
        this.roomLoadedListener = (context: IRoomContext) => {
            this.loadContent(getBioContentPromise(context.dossier.room))
        }
        roomLoaded.listen(this.roomLoadedListener, false)
    }

    private loadContent(content: Promise<IBioParsed>): void {
        if (this.content !== undefined) {
            this.element.removeChild(this.content.main)
        }
        const loading = document.createElement("span")
        loading.innerText = `${i18n.loadingText}...`
        this.element.appendChild(loading)
        this.element.style.display = "block"
        content.then((bio) => {
            this.content = generateContent(bio)
            this.content.main.style.position = "relative"
            this.content.main.style.paddingRight = "3px"
            this.content.main.style.cursor = "default"
            this.content.main.style.overflow = "scroll"
            this.content.main.style.height = "100%"
            this.element.removeChild(loading)
            this.element.appendChild(this.content.main)
            this.repositionChildrenRecursive()
        }).catch((err) => {
            error("Bio Content error", err)
            this.content = generateNoBioContent()
            this.element.removeChild(loading)
            this.element.appendChild(this.content.main)
        })
    }

    repositionChildren(): void {
        const buffer = 5
        if (this.content !== undefined && this.content.infoSection !== undefined) {
            for (const child of this.content.infoSection.childNodes) {
                // We can be certain that this info Section child will be an HTMLDivElement and that the
                // second child is the info text since we made them
                const infoText = child.childNodes[1] as HTMLDivElement | undefined
                // The fanclub links and usercam links can be undefined
                if (infoText !== undefined) {
                    infoText.style.maxWidth = `${this.element.offsetWidth - bioInfoSectionHeadLength - buffer}px`
                }
            }
        }
    }

    protected afterRemovedFromParent(): void {
        roomLoaded.removeListener(this.roomLoadedListener)
    }
}

import {
    getTokenBalance, sendTip,
    tipMessageMargin, tipsInPast24HoursUpdate, tokenBalanceUpdate,
} from "../../cb/api/tipping"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { TransparentCheckbox } from "../../cb/components/toggle"
import { pageContext } from "../../cb/interfaces/context"
import { cleanTipAmountInput, createTipAmountInput, isValidTipInput, popUpPurchasePage, popUpTokenPurchaseModal, purchaseTokensUrl, recordTipTypeViewed } from "../../cb/ui/tipping"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert } from "../alerts"
import { normalizeResource } from "../api"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { EventRouter } from "../events"
import { getViewportWidth } from "../mobilelib/viewportDimension"
import { addPageAction } from "../newrelic"
import { RoomStatus } from "../roomStatus"
import { RoomType } from "../roomUtil"
import { SendTipButton } from "../theatermodelib/sendTipButton"
import { i18n } from "../translation"
import { videoModeHandler } from "../videoModeHandler"
import type { ITipRequest } from "../specialoutgoingmessages"
import type { ITipSent } from "../theatermodelib/tipCallout"

export class TipCallout extends Component {
    private roomName: string
    private roomType = RoomType.Public
    private tokenBalanceDiv: HTMLDivElement
    private tokenBalanceSpan: HTMLSpanElement
    private tokenBalance = 0
    private isTokenAmountAlreadyTooHigh = false
    private sendTipButton: SendTipButton
    private sendTipForm: HTMLFormElement
    private tipAmountInput: HTMLInputElement
    private tipMessageLabel: HTMLDivElement
    private tipMessageDiv: HTMLDivElement
    private tipMessageTextarea: HTMLTextAreaElement
    private tipOptionsSelect: HTMLSelectElement | undefined
    private invalidTipAmountDiv: HTMLDivElement
    private lowScoreWarning: HTMLDivElement
    private stayOpenContainer: HTMLDivElement
    private ctrlSNotice: HTMLDivElement
    private bottomLeft: HTMLDivElement
    private isHighTipAmountWarningActive = false

    tipSent = new EventRouter<ITipSent>("tipSent")
    notifyAttemptSendTip = new EventRouter<ITipSent>("notifyAttemptSendTip")
    shouldReposition = new EventRouter<undefined>("tipCalloutShouldReposition")
    stayOpen = false

    constructor(private tipSource: string) {
        super()

        this.element.id = "FVTipCallout" // for color css
        addColorClass(this.element, colorClass.defaultTooltipColor)
        this.element.style.minWidth = "368px"
        this.element.style.overflow = "visible"
        this.element.style.position = "static"
        this.element.style.fontSize = "13px"

        // region DOM Creation
        const tokenBalanceDiv = document.createElement("div")
        tokenBalanceDiv.style.display = "inline-block"
        const balanceLabel = document.createElement("span")
        balanceLabel.innerText = i18n.currentBalanceText
        balanceLabel.style.display = "inline-block"
        balanceLabel.style.fontWeight = "bold"
        balanceLabel.style.padding = "6px"
        tokenBalanceDiv.appendChild(balanceLabel)
        this.tokenBalanceSpan = document.createElement("span")
        this.tokenBalanceSpan.dataset.testid = "token-balance"
        addColorClass(this.tokenBalanceSpan, "tokenBalance")
        this.tokenBalanceSpan.innerText = `${i18n.loadingTextLower}...`
        this.tokenBalanceSpan.style.display = "inline-block"
        this.tokenBalanceSpan.style.fontWeight = "bold"
        this.tokenBalanceSpan.style.padding = "6px 6px 6px 0"
        tokenBalanceDiv.appendChild(this.tokenBalanceSpan)

        roomLoaded.listen(context => {
            this.roomName = context.chatConnection.room()
            context.chatConnection.event.statusChange.listen(roomStatusChangeNotification => {
                this.roomType = roomStatusChangeNotification.currentStatus === RoomStatus.PrivateWatching ? RoomType.Private : RoomType.Public
            })
        })

        tokenBalanceUpdate.listen((tokenBalanceUpdateNotification) => {
            this.updateTokenBalance(tokenBalanceUpdateNotification.tokens)
        })

        const purchaseTokensLink = document.createElement("a")
        purchaseTokensLink.dataset.testid = "purchase-tokens"
        addColorClass(purchaseTokensLink, "purchaseTokens")
        purchaseTokensLink.innerText = i18n.getMoreTokensLabel
        purchaseTokensLink.href = normalizeResource(`${purchaseTokensUrl}?source=${pageContext.current.PurchaseEventSources["TOKEN_SOURCE_TIP_CALLOUT"]}`)
        purchaseTokensLink.style.display = "inline-block"
        purchaseTokensLink.style.position = "absolute"
        purchaseTokensLink.style.right = "0"
        purchaseTokensLink.style.padding = "6px"
        purchaseTokensLink.onclick = () => {
            popUpPurchasePage({ source: pageContext.current.PurchaseEventSources["TOKEN_SOURCE_TIP_CALLOUT"], target: purchaseTokensLink })
            return false
        }
        purchaseTokensLink.onmouseenter = () => {purchaseTokensLink.style.textDecoration = "underline"}
        purchaseTokensLink.onmouseleave = () => {purchaseTokensLink.style.textDecoration = "none"}

        tokenBalanceDiv.appendChild(purchaseTokensLink)
        this.element.appendChild(tokenBalanceDiv)
        this.tokenBalanceDiv = tokenBalanceDiv

        this.lowScoreWarning = document.createElement("div")
        addColorClass(this.lowScoreWarning, "warning")
        this.lowScoreWarning.style.display = "none"
        this.lowScoreWarning.style.fontWeight = "bold"
        this.lowScoreWarning.style.padding = "6px"
        this.lowScoreWarning.style.textAlign = "center"
        this.lowScoreWarning.style.position = "absolute"
        this.lowScoreWarning.style.left = "50%"
        const lowScoreWarningTop = document.createElement("div")
        lowScoreWarningTop.innerText = i18n.satisfactionWarningText
        this.lowScoreWarning.appendChild(lowScoreWarningTop)
        const lowScoreWarningBottom = document.createElement("div")
        lowScoreWarningBottom.innerText = i18n.tipWarningText
        this.lowScoreWarning.appendChild(lowScoreWarningBottom)
        this.element.appendChild(this.lowScoreWarning)

        this.sendTipForm = document.createElement("form")

        const tipAmountDiv = document.createElement("label")
        const tipAmountLabel = document.createElement("span")
        tipAmountLabel.innerText = i18n.tipAmountText
        tipAmountLabel.style.display = "inline-block"
        tipAmountLabel.style.padding = "6px"
        tipAmountDiv.appendChild(tipAmountLabel)
        this.tipAmountInput = createTipAmountInput()
        this.tipAmountInput.style.width = "5em"
        this.tipAmountInput.style.display = "inline-block"
        this.tipAmountInput.style.padding = "4px"
        this.tipAmountInput.style.borderWidth = "1px"
        this.tipAmountInput.style.borderStyle = "solid"
        this.tipAmountInput.style.borderRadius = "4px"
        this.tipAmountInput.onclick = () => {
            this.focusTipAmount()
        }
        addEventListenerPoly("input", this.tipAmountInput, () => {
            this.tipAmountChange()
        })

        this.invalidTipAmountDiv = document.createElement("div")
        this.invalidTipAmountDiv.dataset.testid = "invalid-tip"
        addColorClass(this.invalidTipAmountDiv, "warning")
        this.invalidTipAmountDiv.innerText = i18n.tipAmountInvalid
        this.invalidTipAmountDiv.style.display = "none"
        this.invalidTipAmountDiv.style.paddingLeft = "5px"

        tipAmountDiv.appendChild(this.tipAmountInput)
        tipAmountDiv.appendChild(this.invalidTipAmountDiv)
        this.sendTipForm.appendChild(tipAmountDiv)

        const tipMessageBlock = document.createElement("label")
        this.tipMessageLabel = document.createElement("div")
        this.tipMessageLabel.innerText = i18n.defaultTipMessageLabel
        this.tipMessageLabel.style.padding = "6px 6px 0 6px"
        tipMessageBlock.appendChild(this.tipMessageLabel)

        this.tipMessageDiv = document.createElement("div")
        this.tipMessageTextarea = document.createElement("textarea")
        this.tipMessageTextarea.dataset.testid = "tip-message-textarea"
        addColorClass(this.tipMessageTextarea, "tipMessageInput")
        this.tipMessageTextarea.maxLength = 255
        this.tipMessageTextarea.style.width = "100%"
        this.tipMessageTextarea.style.resize = "none"
        this.tipMessageTextarea.style.margin = `${tipMessageMargin}px`
        this.tipMessageTextarea.style.padding = "4px"
        this.tipMessageTextarea.style.borderWidth = "1px"
        this.tipMessageTextarea.style.borderStyle = "solid"
        this.tipMessageTextarea.style.borderRadius = "4px"
        this.tipMessageTextarea.style.boxSizing = "border-box"
        this.tipMessageDiv.appendChild(this.tipMessageTextarea)

        this.tipMessageLabel.onclick = () => {
            this.tipMessageTextarea.select()
        }

        tipMessageBlock.appendChild(this.tipMessageDiv)
        this.sendTipForm.appendChild(tipMessageBlock)

        const sendTipButtonDiv = document.createElement("div")
        sendTipButtonDiv.style.position = "relative"
        sendTipButtonDiv.style.display = "flex"
        sendTipButtonDiv.style.flexWrap = "wrap-reverse"
        this.bottomLeft = document.createElement("div")
        this.ctrlSNotice = document.createElement("div")
        this.ctrlSNotice.innerText = i18n.toggleWindowMessage
        this.ctrlSNotice.style.display = "none"
        this.bottomLeft.style.fontSize = "10px"
        this.bottomLeft.style.lineHeight = "1.3em"
        this.bottomLeft.style.padding = "6px"
        this.bottomLeft.style.textAlign = "left"
        this.bottomLeft.style.display = "inline-block"
        sendTipButtonDiv.appendChild(this.bottomLeft)
        this.bottomLeft.appendChild(this.ctrlSNotice)

        this.sendTipButton = new SendTipButton()
        this.addChild(this.sendTipButton, sendTipButtonDiv)

        this.stayOpenContainer = document.createElement("div")
        this.stayOpenContainer.style.fontSize = "10px"
        const stayOpenLabel = document.createElement("label")
        const stayOpenText = document.createElement("span")
        stayOpenText.innerText = i18n.leaveOpenCheckBoxLabel
        stayOpenText.style.cursor = "pointer"
        const stayOpenToggle = new TransparentCheckbox(12)
        stayOpenToggle.element.dataset.testid = "stay-open-toggle"
        stayOpenToggle.element.style.top = "2px"
        stayOpenToggle.element.style.marginRight = "3px"
        stayOpenToggle.setOnChange(() => {
            this.stayOpen = !this.stayOpen
        })

        stayOpenLabel.appendChild(stayOpenToggle.element)
        stayOpenLabel.appendChild(stayOpenText)

        this.stayOpenContainer.appendChild(stayOpenLabel)
        this.bottomLeft.appendChild(this.stayOpenContainer)
        this.sendTipForm.appendChild(sendTipButtonDiv)

        const handleShiftTab = () => {
            if (this.sendTipButton.hasFocus() && !this.sendTipButton.focusPrev()) {
                stayOpenToggle.focus()
            } else if (stayOpenToggle.isActiveElement()) {
                this.tipMessageTextarea.focus()
            } else if (document.activeElement === this.tipMessageTextarea) {
                if (this.tipOptionsSelect !== undefined) {
                    this.tipOptionsSelect.focus()
                } else {
                    this.tipAmountInput.focus()
                }
            } else if (document.activeElement === this.tipOptionsSelect) {
                this.tipAmountInput.focus()
            } else if (!this.sendTipButton.hasFocus()) {
                this.sendTipButton.focusPrev()
            }
        }

        const handleTab = () => {
            if (this.sendTipButton.hasFocus() && !this.sendTipButton.focusNext()) {
                this.tipAmountInput.focus()
            } else if (document.activeElement === this.tipAmountInput) {
                if (this.tipOptionsSelect !== undefined) {
                    this.tipOptionsSelect.focus()
                } else {
                    this.tipMessageTextarea.focus()
                }
            } else if (document.activeElement === this.tipOptionsSelect) {
                this.tipMessageTextarea.focus()
            } else if (document.activeElement === this.tipMessageTextarea) {
                stayOpenToggle.focus()
            } else if (!this.sendTipButton.hasFocus()) {
                this.sendTipButton.focusNext()
            }
        }

        const handleArrowKeys = (next: boolean) => {
            if (this.sendTipButton.hasFocus()) {
                if (next) {
                    this.sendTipButton.focusNextMenuItem()
                } else {
                    this.sendTipButton.focusPrevMenuItem()
                }
            }
        }

        addEventListenerPoly("keydown", this.element, (event: KeyboardEvent) => {
            if (event.code === "Tab") {
                event.preventDefault()
                if (event.shiftKey) {
                    handleShiftTab()
                } else {
                    handleTab()
                }
            } else if (event.code === "ArrowDown") {
                event.preventDefault()
                handleArrowKeys(true)
            } else if (event.code === "ArrowUp") {
                event.preventDefault()
                handleArrowKeys(false)
            }
        })

        this.element.appendChild(this.sendTipForm)
        // endregion

        this.repositionChildren()
        let rateLimited: number | undefined
        addEventListenerPoly("submit", this.sendTipForm, (event: Event) => {
            event.preventDefault()

            if (!isValidTipInput(this.tipAmountInput.value)) {
                modalAlert(i18n.tipAmountInvalid)
                return
            }

            if (!this.sendTipButton.isEnabled()) {
                return
            }

            const amount = parseInt(this.tipAmountInput.value)

            if (amount > this.tokenBalance) {
                popUpTokenPurchaseModal(i18n.notEnoughTokensMessage, pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"], this.roomType)
                return
            }

            addPageAction("SendTipClicked", { "amount": amount, "location": "PMTab" })
            if (amount > 100 && !this.isHighTipAmountWarningActive) {
                this.sendTipButton.promptUser(i18n.tipConfirmationMessage(amount))
                this.isHighTipAmountWarningActive = true
                this.repositionChildren()
                return
            }
            if (rateLimited === undefined) {
                debug(`Sending ${amount} in a tip..`)
                this.tipAmountInput.blur()
                const currentVideoMode = videoModeHandler.getVideoMode()
                let message = this.tipMessageTextarea.value
                if (this.tipOptionsSelect !== undefined) {
                    message = this.tipMessageTextarea.value === ""
                        ? this.tipOptionsSelect.value
                        : `${this.tipOptionsSelect.value} | ${this.tipMessageTextarea.value}`
                }
                sendTip({
                    roomName: this.roomName,
                    tipAmount: this.tipAmountInput.value,
                    message: message,
                    source: this.tipSource,
                    tipRoomType: this.roomType,
                    tipType: this.sendTipButton.getTipType(),
                    videoMode: currentVideoMode,
                }).then((sendTipResponse) => {
                    if (sendTipResponse.success) {
                        addPageAction("SendTipSuccess", { "amount": amount, "location": "PMTab" })
                    } else {
                        if (sendTipResponse.error !== undefined) {
                            popUpTokenPurchaseModal(`${sendTipResponse.error}`, pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"], this.roomType)
                        } else {
                            error("unknown send tip error")
                        }
                    }
                    this.removeHighTipAmountWarning()
                    this.tipMessageTextarea.value = ""
                    if (sendTipResponse.tipsInPast24Hours !== undefined) {
                        tipsInPast24HoursUpdate.fire({ tokens: sendTipResponse.tipsInPast24Hours, roomName: this.roomName })
                    }
                    this.tipSent.fire({ amount: amount, success: sendTipResponse.success })
                }).catch(err => {
                    error(`Error sending tip (${err})`)
                    this.tipSent.fire({ amount: amount, success: false })
                })
                this.notifyAttemptSendTip.fire({ amount: amount })
                rateLimited = window.setTimeout(() => rateLimited = undefined, 250)
            }
        })
    }

    protected repositionChildren(): void {
        this.tipMessageDiv.style.width = `${Math.max(0, this.element.clientWidth - tipMessageMargin * 2)}px`
    }

    private removeHighTipAmountWarning(): void {
        this.sendTipButton.cancelPrompt()
        this.isHighTipAmountWarningActive = false
    }

    private tipAmountChange(): void {
        cleanTipAmountInput(this.tipAmountInput)
        if (!isValidTipInput(this.tipAmountInput.value)) {
            this.sendTipButton.disable()
            this.invalidTipAmountDiv.style.display = "inline-block"
        } else {
            this.sendTipButton.enable()
            this.invalidTipAmountDiv.style.display = "none"
            if (this.tokenBalance > 0 && parseInt(this.tipAmountInput.value) > this.tokenBalance) {
                if (!this.isTokenAmountAlreadyTooHigh) {
                    addPageAction("TokenAmountTooHigh")
                    this.isTokenAmountAlreadyTooHigh = true
                }
            } else {
                this.isTokenAmountAlreadyTooHigh = false
            }
        }
        if (this.isHighTipAmountWarningActive) {
            this.removeHighTipAmountWarning()
        }
    }

    show(tipRequest: ITipRequest): void {
        // ensure the menu is hidden when the callout opens
        this.sendTipButton.hideMenu()
        if (getViewportWidth() < 450) {
            this.element.style.minWidth = "340px"
        } else {
            this.element.style.minWidth = "368px"
        }
        if (tipRequest.amount !== undefined) {
            this.tipAmountInput.value = tipRequest.amount.toString()
            this.tipAmountChange()
        }
        if (tipRequest.message !== undefined) {
            this.tipMessageTextarea.value = tipRequest.message
        } else {
            this.tipMessageTextarea.value = ""
        }
        if (tipRequest.usedCtrlS !== undefined && tipRequest.usedCtrlS) {
            this.ctrlSNotice.style.display = "none"
            this.stayOpenContainer.style.lineHeight = "3em"
        } else {
            this.ctrlSNotice.style.display = "block"
            this.stayOpenContainer.style.lineHeight = ""
        }

        recordTipTypeViewed(this.tipSource, this.sendTipButton)
        this.repositionChildren()
        this.shouldReposition.fire(undefined)
        this.focusTipAmount()

        getTokenBalance(this.roomName).then((currentTokensResponse) => {
            this.tipAmountInput.max = currentTokensResponse.tokenBalance.toString()

            if (currentTokensResponse.tipOptions !== undefined) {
                this.tipMessageLabel.innerText = currentTokensResponse.tipOptions.label
                if (this.tipOptionsSelect !== undefined) {
                    this.tipMessageDiv.removeChild(this.tipOptionsSelect)
                }
                this.tipOptionsSelect = document.createElement("select")
                this.tipOptionsSelect.dataset.testid = "tip-type-dropdown-button"
                addColorClass(this.tipOptionsSelect, "tipOptionsSelect")
                this.tipOptionsSelect.style.width = "100%"
                this.tipOptionsSelect.style.fontSize = ".8125em"
                this.tipOptionsSelect.style.margin = `${tipMessageMargin}px`
                this.tipOptionsSelect.style.borderWidth = "1px"
                this.tipOptionsSelect.style.borderStyle = "solid"
                this.tipOptionsSelect.style.boxSizing = "border-box"
                this.tipMessageDiv.insertBefore(this.tipOptionsSelect, this.tipMessageTextarea)
                let option = document.createElement("option")
                option.innerText = `-- ${i18n.selectOneLabel} --`
                option.value = ""
                this.tipOptionsSelect.appendChild(option)
                for (const tipOption of currentTokensResponse.tipOptions.options) {
                    option = document.createElement("option")
                    option.innerText = tipOption.label
                    option.value = tipOption.label
                    this.tipOptionsSelect.appendChild(option)
                }
            } else if (this.tipOptionsSelect !== undefined) {
                this.tipMessageLabel.innerText = i18n.defaultTipMessageLabel
                this.tipMessageTextarea.value = ""
                this.tipMessageDiv.removeChild(this.tipOptionsSelect)
                this.tipOptionsSelect = undefined
            }
        }).catch((err) => {
            error(`Error getting token balance (${err})`)
        })
    }

    focusTipAmount(force = false): void {
        if (document.activeElement !== this.tipAmountInput || force) {
            this.tipAmountInput.focus()
            // select() doesn't work on older ipads (5th gen at least)
            this.tipAmountInput.setSelectionRange(0, 9999)
        }
    }

    private updateTokenBalance(balance: number): void {
        this.tokenBalance = balance
        this.tokenBalanceSpan.innerText = `${balance} ${i18n.tokenOrTokensText(balance, false)}`
    }

    getContentSize(): number[] {
        const prevParent = this.element.parentElement
        this.element.style.visibility = "hidden"
        document.body.appendChild(this.element)
        this.element.style.width = `${this.tokenBalanceDiv.offsetWidth}px`
        this.element.style.height = "auto"
        const size = [this.element.offsetWidth, this.element.offsetHeight]
        document.body.removeChild(this.element)
        prevParent?.appendChild(this.element)
        this.element.style.visibility = ""
        return size
    }
}

import { normalizeResource } from "../api"
import { isAnonymous } from "../auth"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { addPageAction } from "../newrelic"
import { i18n } from "../translation"
import { parseQueryString } from "../urlUtil"
import { isInIframe, safeWindowOpen } from "../windowUtils"
import type { IRoomContext } from "../context"

const parsedQueryString = parseQueryString(window.location.search)
export const signupNotice = parsedQueryString["signup_notice"] === "1"

export class AnonymousWelcome extends Component {
    constructor() {
        super()
        this.element.style.backgroundColor = "#f7f6a8"
        this.element.style.fontSize = "12px"
        this.element.style.padding = "3px 10px"
        this.element.style.boxSizing = "border-box"
        this.element.style.display = "none"
        this.element.style.width = "auto"
        this.element.style.height = "auto"
        const a = document.createElement("a")
        a.innerText = i18n.signUpToChatText
        a.onclick = () => {
            addPageAction("Subscribe")
        }
        this.element.appendChild(a)
        this.element.appendChild(document.createTextNode(" -- totally free, no email needed"))

        if (signupNotice) {
            roomLoaded.listen((context: IRoomContext) => {
                if (isInIframe()) {
                    a.onclick = (event: Event) => {
                        event.preventDefault()
                        safeWindowOpen(`/accounts/register/?next=${encodeURIComponent(window.location.pathname)}?b=${context.dossier.room}`)
                    }
                }
                a.href = normalizeResource(`/accounts/register/?next=${encodeURIComponent(window.location.pathname)}?b=${context.dossier.room}`)
                if (isAnonymous()) {
                    this.element.style.display = "block"
                } else {
                    this.element.style.display = "none"
                }
            })
        }
    }
}

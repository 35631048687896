import {
    createNewSession, dmsEnabled, getRoomHistoryMessages, getUserInfo,
    pmHistoryBatchSize,
    PrivateMessageSource,
    sendPrivateMessage,
} from "../../cb/api/pm"
import { DesktopPMChatLink } from "../../cb/components/pm/chatLinks"
import { ConversationList, hideConversationsFromUser } from "../../cb/components/pm/conversationList"
import { ConversationListData } from "../../cb/components/pm/conversationListData"
import { initMediaDragDrop, LibraryMediaDock } from "../../cb/components/pm/mediaDock"
import { goPMList, ignoreUser, LoadHistoryMessagesDOM } from "../../cb/components/pm/pmControlBar"
import { allPmsRead, closePmSession, privateMessage, showPmSession } from "../../cb/components/pm/userActionEvents"
import { pageContext } from "../../cb/interfaces/context"
import { currentSiteSettings } from "../../cb/siteSettings"
import { BaseTab, TabId } from "../../cb/ui/tabs"
import { isAnonymous } from "../auth"
import { roomCleanup, roomLoaded, userViewedPm } from "../context"
import { DefDeck } from "../defDeck"
import { decEventsPmSessionsCount, EventRouter, incEventsPmSessionsCount } from "../events"
import { addPageAction } from "../newrelic"
import { ignoreCatch } from "../promiseUtils"
import { getCurrentRoom, isCurrentUserRoomOwner } from "../roomUtil"
import { createDMChatLinkMessage, createRoomPhotoMessage } from "../theatermodelib/messageToDOM"
import { i18n } from "../translation"
import { getMoreHistoryMessages, userInitiatedPm } from "../userActionEvents"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { ChatTabContents } from "./chatTabContents"
import { createLogMessage, createRoomMessage, createSupporterSignupMessage } from "./messageToDOM"
import { openTipCalloutRequest } from "./userActionEvents"
import type { IPMError, ISendPrivateMessage } from "../../cb/api/pm"
import type { IPMAnnouncement } from "../../cb/components/pm/chatLinks"
import type { IChatWindowTab } from "../../cb/ui/iChatTab"
import type { IChatConnection, IRoomContext } from "../context"
import type { CustomInput } from "../customInput"
import type { IChatMedia , IPrivateMessage } from "../messageInterfaces"

interface IPMSession {
    username: string
    hasUnread: boolean
    chatTabContents: ChatTabContents
    loadHistoryMessagesDOM: LoadHistoryMessagesDOM
    isInitialHistoryLoaded: boolean
    isAllHistoryLoaded: boolean
    mediaDock: LibraryMediaDock
    isActive: () => boolean
}
export class PmTab extends BaseTab implements IChatWindowTab {
    private numUnread = 0
    public otherUserInitiatedPm = new EventRouter<IPMAnnouncement>("otherUserInitiatedPm")
    private pmSessions = new Map<string, IPMSession>()
    private orderedPMSessionKeys = new DefDeck<string>()
    private chatConnection: IChatConnection
    private pmList: ConversationList
    private room: string

    constructor(private leavePmTab: () => void) {
        super()

        // use data-hj-surpress to surpress recordings for private messages due to privacy concern
        // read more about data-hj-surpress on :
        // https://help.hotjar.com/hc/en-us/articles/115012439167-How-to-Suppress-Text-Images-and-User-Input-from-Collected-Data
        this.element.classList.add("data-hj-suppress")
        this.element.style.overflow = ""

        this.pmList = new ConversationList({
            isFullVideoMode: true,
            clearSearchOnSelect: true,
            openConversationEvent: showPmSession,
        })
        this.addChild(this.pmList)

        userInitiatedPm.listen((notification) => {
            this.hideCurrentPMSession()
            this.hidePMList()
            if (this.pmSessions.get(notification.username) === undefined) {
                this.initializePmSession(notification.username, false, notification.showSupporterAlert)
            }
            this.orderedPMSessionKeys.makeCurrent(notification.username)
            this.refreshTabs()
            if (notification.focus) {
                this.changeToThisTab()
            }
            this.showCurrentPMSession()
        })
        roomLoaded.listen((context: IRoomContext) => {
            this.chatConnection = context.chatConnection
            this.room = context.dossier.room
        })
        roomCleanup.listen(() => {
            this.emptyQueue()
            for (const key of this.pmSessions.keys()) {
                this.closePMSession(key, true)
            }
        })
        closePmSession.listen((username: string) => {
            this.closePMSession(username)
        })
        showPmSession.listen((username: string) => {
            this.hideCurrentPMSession()
            this.orderedPMSessionKeys.makeCurrent(username)
            this.showCurrentPMSession()
        })
        goPMList.listen((isFullVideoMode: boolean) => {
            this.showPMList(isFullVideoMode, true)
        })
        ignoreUser.listen(() => {
            this.chatConnection.ignore((this.orderedPMSessionKeys.currentElem as string))  // eslint-disable-line @typescript-eslint/no-floating-promises
        })
        getMoreHistoryMessages.listen(() => {
            const pmSession = this.pmSessions.get((this.orderedPMSessionKeys.currentElem as string)) as IPMSession
            if (pmSession.isAllHistoryLoaded === false) {
                this.loadHistoryMessages(pmSession)
            }
        })
        createNewSession.listen((username: string) => {
            this.orderedPMSessionKeys.addToTop(username)
            this.initializePmSession(username, false, false)
        })
        privateMessage.listen((privateMessage: IPrivateMessage) => {
            this.newPrivateMessage(privateMessage)
            const currentSession = this.currentPmSession()
            if (!this.inActiveVideoMode() || !this.tabHasFocus() || currentSession === undefined || !currentSession.isActive()) {
                return
            }

            this.addPageActions(currentSession, privateMessage)

            if (privateMessage.otherUsername === currentSession.username && !currentSession.chatTabContents.isScrolledUp()) {
                allPmsRead.fire(currentSession.username)
            }
        })
        ConversationListData.unreadConversationsCountUpdate.listen(({ pmsCount }) => {
            this.setNumUnread(pmsCount)
        })
    }

    private addPageActions(session: IPMSession, message: IPrivateMessage, fromHistory = false) {
        const messageIsFromSelf = pageContext.current.loggedInUser?.username === message.fromUser.username
        const messageIsForCurrentSession = session.username === message.fromUser.username

        if (session.isActive() && !fromHistory && !messageIsFromSelf && messageIsForCurrentSession) {
            addPageAction("PMReceivedOpen", { "message_id": message.messageID })
        }
    }

    private showPMList(isFullVideoMode: boolean, viewList = false): void {
        if (isFullVideoMode) {
            this.hideCurrentPMSession()
            const viewMyself = isCurrentUserRoomOwner()
            if (viewList || this.pmSessions.size > 1 || viewMyself) {
                this.pmList.element.style.display = "block"
                this.pmList.repositionChildrenRecursive()
            } else {
                this.orderedPMSessionKeys.makeCurrent(getCurrentRoom())
                this.showCurrentPMSession()
            }
        }
    }

    private hidePMList(): void {
        this.pmList.element.style.display = "none"
    }

    public scrollToBottom(): void {
        const pmSession = this.currentPmSession()
        if (pmSession === undefined) {
            return
        }
        pmSession.chatTabContents.scrollToBottom()
    }

    private getNumUnread(): number {
        return this.numUnread
    }

    private setNumUnread(numUnread: number): void {
        if (numUnread === this.numUnread) {
            return
        }
        this.numUnread = numUnread
        this.refreshTabs()
    }


    public getTabHandleContent(): Node[] {
        const baseTabTitle = i18n.PmCAPS
        let message
        if (this.numUnread > 99) {
            message = `${baseTabTitle} (99+)`
        } else if (this.numUnread > 0) {
            message = `${baseTabTitle} (${this.numUnread})`
        } else {
            message = baseTabTitle
        }
        return [document.createTextNode(message)]
    }

    public tabHandleClicked(ev: MouseEvent): void {
        addPageAction("FocusTab", { "location": "PM", "unread": this.getNumUnread() })
        // hide PM session first otherwise any unreads from the previously open conversation will be marked read
        // when the tab changes
        this.hideCurrentPMSession()
        // change to tab first, since scrollToBottom() requires elements to be visible to work
        this.changeToThisTab()
        this.showPMList(true)
    }

    public getTabId(): TabId {
        return TabId.PrivateFvm
    }

    private tabHasFocus(): boolean {
        return this.element.style.display === "block"
    }

    public focusCurrentChatInput(): void {
        const session = this.currentPmSession()
        if (session === undefined) {
            return
        }
        session.chatTabContents.focusCurrentChatInput()
    }

    public blurCurrentChatInput(): void {}

    public isInputFocused(): boolean {
        const session = this.currentPmSession()
        if (session === undefined) {
            return false
        }
        return session.chatTabContents.isInputFocused()
    }

    private sendPrivateMessageFailCallback = (error: IPMError): void => {
        const pmUser = this.orderedPMSessionKeys.currentElem as string
        const pmSession = this.pmSessions.get(pmUser) as IPMSession
        if (dmsEnabled() && error.showDmLink) {
            pmSession.chatTabContents.appendMessageDiv(createDMChatLinkMessage(error.errorMessage, error.username))
        } else {
            pmSession.chatTabContents.appendMessageDiv(createLogMessage(error.errorMessage))
        }
    }

    private initializePmSession(pmUser: string, otherUserInitiated: boolean, showSupporterAlert: boolean, fromHistory = false): IPMSession {
        let pmSession = this.pmSessions.get(pmUser)
        if (pmSession === undefined) {
            incEventsPmSessionsCount()
            const pmChatTabContents = new ChatTabContents({
                onTipRequest: (tipRequest) => {
                    openTipCalloutRequest.fire(tipRequest)
                },
                onToggleDebugMode: () => {
                    this.chatConnection.toggleAppDebugging()
                },
                onChatMessage: (msg) => {
                    this.orderedPMSessionKeys.addToTop(pmUser)
                    const arr: IChatMedia[] = []
                    const pm: ISendPrivateMessage = {
                        message: msg,
                        username: pmUser,
                        media: arr,
                        source: PrivateMessageSource.RoomViewPM,
                        roomName: this.chatConnection.room(),
                    }
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const selectedMediaDock = pmSession!.mediaDock.sibling
                    if (!selectedMediaDock.isEmpty()) {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        selectedMediaDock.mediaList().then((media) => {
                            pm.media = media
                            sendPrivateMessage(pm).catch(err => {
                                this.sendPrivateMessageFailCallback(err)
                            })
                            selectedMediaDock.clear()
                        })
                    } else {
                        sendPrivateMessage(pm).catch(error => {
                            this.sendPrivateMessageFailCallback(error)
                        })
                    }
                },
            }, () => false, () => this.isConversationShowing(pmUser), pmUser)
            pmSession = {
                chatTabContents: pmChatTabContents,
                hasUnread: false,
                username: pmUser,
                loadHistoryMessagesDOM: new LoadHistoryMessagesDOM(),
                isInitialHistoryLoaded: false,
                isAllHistoryLoaded: false,
                mediaDock: new LibraryMediaDock(pmChatTabContents, pmUser, [VideoMode.Theater, VideoMode.Fullvideo, VideoMode.Fullscreen]),
                isActive: () => pmChatTabContents.element.style.display !== "none",
            }
            pmChatTabContents.scrolledToBottom.listen(() => {
                if (pmSession !== undefined && this.isPmSessionShowing(pmSession)) {
                    allPmsRead.fire(pmUser)
                }
            })

            this.pmSessions.set(pmUser, pmSession)

            initMediaDragDrop(pmSession.chatTabContents.element, pmUser)
            pmSession.chatTabContents.initMediaDocks(pmSession.mediaDock)

            pmSession.chatTabContents.appendNoticeDiv(createLogMessage(i18n.privateConversationWithText(pmUser)))
            pmSession.chatTabContents.appendNoticeDiv(createLogMessage(i18n.conversationCautionMessage(currentSiteSettings.siteName)))
            if (!otherUserInitiated && showSupporterAlert) {
                const isViewerAgeVerified = pageContext.current.loggedInUser?.isAgeVerified ?? false
                pmSession.chatTabContents.appendNoticeDiv(createSupporterSignupMessage(pmUser, isViewerAgeVerified))
            } else {
                pmSession.chatTabContents.appendNoticeDiv(pmSession.loadHistoryMessagesDOM.getElement())
            }
            pmSession.chatTabContents.element.style.display = "none"
            this.addChild(pmSession.chatTabContents)
        }
        if (!fromHistory && otherUserInitiated) {
            this.otherUserInitiatedPm.fire({
                username: pmUser,
                PMChatLink: new DesktopPMChatLink({
                    onClick: () => {
                        userInitiatedPm.fire({ username: pmUser, focus: true, showSupporterAlert: false })
                    },
                }),
            })
        }
        if (this.orderedPMSessionKeys.currentElem === undefined) {
            this.orderedPMSessionKeys.makeCurrent(pmUser)
        }
        return pmSession
    }

    private loadHistoryMessages(pmSession: IPMSession, initialHistory = false): Promise<void> {
        if (pmSession.loadHistoryMessagesDOM.isShowing()) {
            return Promise.reject()
        }
        pmSession.loadHistoryMessagesDOM.showLoading()
        const oldListLength = pmSession.chatTabContents.messageList.clientHeight
        const offset = initialHistory ? "0" : pmSession.chatTabContents.getEarliestMessageId()
        return getRoomHistoryMessages(pmSession.username, this.chatConnection.room(), offset).then(pmList => {
            pmSession.loadHistoryMessagesDOM.hideLoading()
            const messages = pmList.messages
            if (messages.length < pmHistoryBatchSize) {
                pmSession.isAllHistoryLoaded = true
            }
            // each pmSession has its own numUnread which needs to be used to append the newLine notice properly before latest unread
            const numUnread = ConversationListData.getInstance().getConversation(pmSession.username)?.numUnread ?? -1
            messages.forEach((pm: IPrivateMessage, index: number) => {
                pm.isFirstHistoryUnread = index === messages.length - numUnread
                this.newPrivateMessage(pm, true)
            })
            if (messages.length > 0) {
                pmSession.chatTabContents.setEarliestMessageId(messages[0].messageID)
            }
            if (initialHistory) {
                this.maybeUpdateConversationListItem(messages)
            }
            const newListLength = pmSession.chatTabContents.messageList.clientHeight
            pmSession.chatTabContents.messageListWrapper.scrollTop = newListLength - oldListLength
        }).catch(ignoreCatch)
    }

    private maybeUpdateConversationListItem(pmList: IPrivateMessage[]) {
        if (pmList.length === 0) {
            return
        }
        const lastMessage = pmList[pmList.length - 1]
        getUserInfo(lastMessage.otherUsername, this.room).then((userInfoAndUnread) => {
            ConversationListData.conversationLoaded.fire({
                message: lastMessage.message,
                numUnread: this.numUnread ?? 0,
                time: lastMessage.createdAt?.getTime(),
                fromUsername: lastMessage.fromUser.username,
                otherUser: userInfoAndUnread.user,
                hasMedia: lastMessage.mediaList.length > 0,
                room: this.room,
            })
        }).catch(ignoreCatch)
    }

    private newPrivateMessage(pm: IPrivateMessage, fromHistory = false): void {
        this.appendMessageDiv(createRoomMessage(pm), pm, fromHistory, false)
        const photoMessage = createRoomPhotoMessage(pm)
        if (photoMessage !== undefined) {
            this.appendMessageDiv(photoMessage, pm, fromHistory, true)
        }
    }

    private appendMessageDiv(c: HTMLDivElement, pm: IPrivateMessage, fromHistory: boolean, isPhotoMessage: boolean): void {
        this.orderedPMSessionKeys.addToTop(pm.otherUsername)
        const pmSession = this.initializePmSession(pm.otherUsername, pm.fromUser.username!== this.chatConnection.username(), false, fromHistory)
        if (fromHistory && pmSession.isInitialHistoryLoaded === false) {
            pmSession.isInitialHistoryLoaded = true
        }
        if (this.sessionHasUnread(pm, pmSession)) {
            pmSession.hasUnread = true
            this.refreshTabs()
        }
        const isMine = this.chatConnection.username() === pm.fromUser.username
        if (pmSession.isInitialHistoryLoaded) {
            pmSession.chatTabContents.appendMessageDiv(c, !fromHistory && !isPhotoMessage && !isMine, pm.isFirstHistoryUnread)
        }
    }

    private sessionHasUnread(pm: IPrivateMessage, pmSession: IPMSession): boolean {
        const conversationItemNumUnread = ConversationListData.getInstance().getConversation(pmSession.username)?.numUnread ?? 0

        return this.chatConnection.username() !== pm.fromUser.username &&
        (!this.isCurrentTab() || this.orderedPMSessionKeys.currentElem !== pm.otherUsername || conversationItemNumUnread > 0)
    }

    public possiblyAppendMessageDiv(div: HTMLDivElement): void {
        if (this.isCurrentTab()) {
            const session = this.currentPmSession()
            if (session !== undefined) {
                session.chatTabContents.appendMessageDiv(div)
            }
        }
    }

    private currentPmSession(): IPMSession | undefined {
        if (this.orderedPMSessionKeys.currentElem === undefined) {
            return undefined
        }
        return this.pmSessions.get(this.orderedPMSessionKeys.currentElem)
    }

    // showNextPMSession returns false if there are no more sessions
    public showNextPMSession(): boolean {
        return this.showNextOrPrevPMSession("next")
    }

    public showPrevPMSession(): boolean {
        return this.showNextOrPrevPMSession("prev")
    }

    private showNextOrPrevPMSession(direction: "next" | "prev"): boolean {
        this.hideCurrentPMSession()
        const nextElem = direction === "next" ? this.orderedPMSessionKeys.nextElem() : this.orderedPMSessionKeys.prevElem()
        if (nextElem === undefined) {
            return false
        }
        this.showCurrentPMSession()
        return true
    }

    public closePMSession(username: string, force = false): void {
        if (username === this.room && !force) {
            return
        }
        const session = this.pmSessions.get(username)
        if (session === undefined) {
            return
        }
        this.orderedPMSessionKeys.remove(username)
        session.chatTabContents.dispose()
        this.removeChild(session.chatTabContents)
        this.pmSessions.delete(username)
        if (this.isCurrentTab()) {
            if (this.pmSessions.size > 0) {
                this.showPMList(true, true)
            } else {
                this.leavePmTab()
            }
        }
        this.refreshTabs()
        decEventsPmSessionsCount()
        hideConversationsFromUser(session.username, false)
    }

    public closeCurrentPMSession(): void {
        const oldSession = this.currentPmSession()
        if (oldSession === undefined) {
            return
        }
        this.closePMSession(oldSession.username)
    }

    public resetNextQueue(): void {
        this.hideCurrentPMSession()
        this.orderedPMSessionKeys.refillFromDiscard()
    }

    public resetPrevQueue(): void {
        this.hideCurrentPMSession()
        this.orderedPMSessionKeys.emptyDrawIntoDiscard()
    }

    private emptyQueue(): void {
        this.hideCurrentPMSession()
        this.orderedPMSessionKeys = new DefDeck<string>()
    }

    private showCurrentPMSession(): void {
        let currentSessionKey = this.orderedPMSessionKeys.currentElem
        if (currentSessionKey === undefined) {
            currentSessionKey = this.chatConnection.room()
            this.orderedPMSessionKeys.makeCurrent(currentSessionKey)
        }

        let newSession = this.pmSessions.get(currentSessionKey)
        if (newSession === undefined) {
            this.initializePmSession(currentSessionKey, false, false)
        }
        newSession = this.pmSessions.get(currentSessionKey) as IPMSession
        const wasInitiallyLoaded = newSession.isInitialHistoryLoaded
        if (!wasInitiallyLoaded && !isAnonymous()) {
            newSession.isInitialHistoryLoaded = true
            this.loadHistoryMessages(newSession, true).then(() => {
                if (newSession !== undefined) {
                    this.maybeMarkSessionRead(newSession)
                }
            }).catch(ignoreCatch)
        }

        this.hidePMList()
        newSession.chatTabContents.showElement()
        newSession.chatTabContents.repositionChildrenRecursive()
        newSession.chatTabContents.scrollToBottom()

        if (wasInitiallyLoaded) {
            this.maybeMarkSessionRead(newSession)
        }
    }

    private maybeMarkSessionRead(session: IPMSession): void {
        if (!this.tabHasFocus() || !this.inActiveVideoMode()) {
            return
        }
        if (session.hasUnread) {
            session.hasUnread = false
            this.refreshTabs()
        }
        allPmsRead.fire(session.username)
        userViewedPm.fire(session.username)
    }

    public getSession(username: string): IPMSession | undefined {
        return this.pmSessions.get(username)
    }

    private hideCurrentPMSession(): void {
        const currentlyDisplayedPmSession = this.currentPmSession()
        if (currentlyDisplayedPmSession !== undefined) {
            currentlyDisplayedPmSession.chatTabContents.element.style.display = "none"
        }
    }

    public getChatInputField(): CustomInput | undefined {
        const pmSession = this.currentPmSession()
        if (pmSession === undefined) {
            return undefined
        }
        return pmSession.chatTabContents.customInputField
    }

    private inActiveVideoMode(): boolean {
        const videoMode = videoModeHandler.getVideoMode()
        return videoMode === VideoMode.Theater || videoMode === VideoMode.Fullvideo || videoMode === VideoMode.IFS
    }

    private isPmSessionShowing(pmSession: IPMSession): boolean {
        return this.inActiveVideoMode() && this.tabHasFocus() && this.currentPmSession() === pmSession && this.pmList.element.style.display === "none"
    }

    private isConversationShowing(pmUser: string): boolean {
        const session = this.getSession(pmUser)
        if (session === undefined) {
            return false
        }
        const currTabId = this.parent?.getCurrentTab().getTabId()
        const isChatOrPMTabShowing = currTabId === TabId.ChatFvm || currTabId === TabId.PrivateFvm
        // In private shows, both PMTab and ChatTab are used for the private show chat
        const inPrivateAndAnyChatShowing = this.chatConnection && this.chatConnection.inPrivateRoom() && isChatOrPMTabShowing // eslint-disable-line @typescript-eslint/strict-boolean-expressions
        return this.isPmSessionShowing(session) || inPrivateAndAnyChatShowing && this.inActiveVideoMode()
    }
}

import { hasWellSupportedEmojis } from "@multimediallc/web-utils/modernizr"
import twemoji from "@twemoji/api"
import { addColorClass } from "../../cb/colorClasses"
import { dom } from "../../common/tsxrender/dom"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { Debouncer, DebounceTypes } from "../debouncer"
import { createDivotBottom } from "../divot"
import { applyStyles } from "../DOMutils"
import {
    EmojiGroups, emojiStringToArray,
    getEmojiGroup, isSingleTwemoji,
    loadAllEmojis, recordRecentEmoji,
    searchEmojis,
} from "../emojis"
import { EventRouter, ListenerGroup } from "../events"
import { fullscreenElement, isFullscreen } from "../fullscreen"
import { isCharacterKey } from "../ischaracterkey"
import { addPageAction } from "../newrelic"
import { OverlayComponent } from "../overlayComponent"
import { i18n } from "../translation"
import { safeWindowOpen } from "../windowUtils"
import Key = JQuery.Key
import type { CustomInput } from "../customInput"
import type { IEmoji } from "../emojis"

export const emojiAdded = new EventRouter<undefined>("emojiAdded")

const enum EmojiTone {
    Light = "🏻",
    MediumLight = "🏼",
    Medium = "🏽",
    MediumDark = "🏾",
    Dark = "🏿",
    Default = "",
}
const toneList = [EmojiTone.Light, EmojiTone.MediumLight, EmojiTone.Medium, EmojiTone.MediumDark, EmojiTone.Dark, EmojiTone.Default]
const toneChange = new EventRouter<EmojiTone>("toneChange", { listenersWarningThreshold: 500 })
const toneExampleEmoji = "👋"
const toneStorageKey = "emojiTone"

export class EmojiSelectionModal extends OverlayComponent {
    private emojiSections: HTMLDivElement
    private recentEmojisContent: HTMLDivElement
    private recentEmojisRebuildData: IEmoji[] | undefined
    private searchBar: HTMLInputElement
    private searchResults: HTMLDivElement
    private currentToneContainer: HTMLDivElement
    private currentToneSpan: HTMLSpanElement
    private toneOptions: HTMLDivElement
    private currentTone = EmojiTone.Default
    private recentEmojisToneListeners = new ListenerGroup()
    private searchResultsToneListeners = new ListenerGroup()
    private bottomDivot: HTMLDivElement
    private isVisible = false

    constructor(private getCurrentChatInput: () => CustomInput | undefined) {
        super()

        this.addAllSections()
        this.overlayClick.listen(() => {
            this.hide()
        })
        addEventListenerPoly("keydown", document, (event: KeyboardEvent) => {
            if (isCharacterKey(event.which) || event.keyCode === Key.Escape || event.keyCode === Key.Enter) {
                this.hide()
            }
        })
        addEventListenerPoly("keydown", this.searchBar, (event: KeyboardEvent) => {
            event.stopPropagation()
        })
        addEventListenerPoly("click", this.element, (event) => {
            event.stopPropagation()
            window.setTimeout(() => {
                if (this.searchBar.value !== "") {
                    this.searchBar.focus()
                }
            }, 0)
        })
        addEventListenerPoly("resize", window, () => { this.hide() })
    }

    protected initUI(): void {
        super.initUI()
        this.overlay.className = "emojiSelectionModalOverlay"

        const emojiModalStyle: CSSX.Properties = {
            position: "absolute",
            visibility: "hidden",
            width: "284px",
            height: "261px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
            boxSizing: "border-box",
            fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
            fontSize: "12px",
            zIndex: 1001,
        }
        const searchBarContainerStyle: CSSX.Properties = {
            position: "static",
            width: "calc(100% - 8px)",
            height: "28px",
            boxSizing: "border-box",
            margin: "4px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
        }
        const searchIconStyle: CSSX.Properties = {
            position: "relative",
            width: "15px",
            height: "15px",
            top: "50%",
            transform: "translateY(-50%)",
            cssFloat: "left",
            left: "7px",
        }
        const searchBarStyle: CSSX.Properties = {
            position: "static",
            width: "calc(100% - 10px)",
            height: "calc(100% - 2px)",
            padding: "0px",
            borderWidth: "0px",
            marginLeft: "4px",
            outline: "none",
        }
        const emojiSectionsStyle: CSSX.Properties = {
            position: "relative",
            padding: "8px 0px",
            height: "219px",
            boxSizing: "border-box",
            overflowY: "scroll",
        }
        const uploadEmoticonLinkStyle: CSSX.Properties = {
            position: "absolute",
            lineHeight: "14px",
            right: "22px",
            top: "9px",
            cursor: "pointer",
        }

        const searchChangeDebouncer = new Debouncer(() => {
            this.handleSearchChange()
        }, {
            bounceLimitMS: 200,
            debounceType: DebounceTypes.debounce,
        })
        this.bottomDivot = createDivotBottom("", "", "", 1)
        addColorClass(this.bottomDivot, "divot")
        this.bottomDivot.style.bottom = "-37px"

        let uploadLink: HTMLAnchorElement
        this.element =
            <div style={emojiModalStyle} colorClass="emojiSelectionModal" data-testid="emoji-picker-modal">
                <div style={searchBarContainerStyle} colorClass="searchBar">
                    <div style={searchIconStyle} colorClass="searchIcon"/>
                    <div style={{ marginLeft: "22px", height: "100%" }}>
                        <input style={searchBarStyle}
                            ref={(el: HTMLInputElement) => { this.searchBar = el }}
                            onInput={() => { searchChangeDebouncer.callFunc() }}
                            data-testid="emoji-search-bar"/>
                    </div>
                </div>
                <div style={emojiSectionsStyle}
                    ref={(el: HTMLDivElement) => { this.emojiSections = el }}
                    data-testid="all-emojis">
                    <a style={uploadEmoticonLinkStyle}
                        ref={(el: HTMLAnchorElement) => { uploadLink = el }}
                        onMouseEnter={() => { applyStyles(uploadLink, { textDecoration: "underline" }) }}
                        onMouseLeave={() => { applyStyles(uploadLink, { textDecoration: "" }) }}
                        colorClass="uploadLink"
                        onClick={() => this.onClickUploadLink()}
                        data-testid="upload-emoticon-button">
                        {i18n.uploadEmoticonsText}
                    </a>
                </div>
                <div data-testid="search-result-emojis" style={Object.assign({ display: "none" }, emojiSectionsStyle)}
                    ref={(el: HTMLDivElement) => { this.searchResults = el }}>
                </div>
                {!hasWellSupportedEmojis() && this.createToneSelect()}
                {!hasWellSupportedEmojis() && this.bottomDivot}
            </div>
    }

    private addAllSections(): void {
        loadAllEmojis()
        this.addSection(i18n.recentlyUsedEmojis, EmojiGroups.recent, (content: HTMLDivElement) => {
            this.recentEmojisContent = content
        })
        this.addSection(i18n.smileysPeopleEmojis, EmojiGroups.smileysAndPeople)
        this.addSection(i18n.animalsNatureEmojis, EmojiGroups.animalsAndNature)
        this.addSection(i18n.foodDrinkEmojis, EmojiGroups.foodAndDrink)
        this.addSection(i18n.activitiesEmojis, EmojiGroups.activities)
        this.addSection(i18n.travelPlacesEmojis, EmojiGroups.travelAndPlaces)
        this.addSection(i18n.objectsEmojis, EmojiGroups.objects)
        this.addSection(i18n.symbolsEmojis, EmojiGroups.symbols)
        this.addSection(i18n.flagsEmojis, EmojiGroups.flags)
    }

    private addSection(title: string, groupName: EmojiGroups, contentCallback = (content: HTMLDivElement) => { }): void {
        const titleStyle: CSSX.Properties = { lineHeight: "16px" }

        let content: HTMLDivElement
        const section =
            <div data-testid={`${groupName}-emojis`}>
                <div style={titleStyle} colorClass="sectionTitle">{title}</div>
                <div ref={(el: HTMLDivElement) => {
                    content = el
                    contentCallback(el)
                }}
                />
            </div>
        this.emojiSections.appendChild(section)

        getEmojiGroup(groupName).then((emojis) => {
            this.populateSectionContent(content, emojis)
        }).catch(() => { })
    }

    private populateSectionContent(contentDiv: HTMLDivElement, emojisData: IEmoji[], listenerGroup?: ListenerGroup): void {
        for (const emojiData of emojisData) {
            const emojiDivStyle: CSSX.Properties = {
                position: "relative",
                display: "inline-block",
                width: "29px",
                height: "32px",
                cursor: "pointer",
                borderRadius: "2px",
                fontSize: "22px",
            }

            const emojiDivUnicodeStyle: CSSX.Properties = {
                position: "relative",
                display: "inline-block",
                width: "28px",
                height: "28px",
                cursor: "pointer",
                borderRadius: "2px",
                fontSize: "22px",
                textAlign: "center",
                lineHeight: "28px",
                paddingLeft: "1px",
            }

            const emojiDiv =
                <div data-testid="emoji-item" style={hasWellSupportedEmojis() ? emojiDivUnicodeStyle : emojiDivStyle} colorClass="emojiDiv"
                    onClick={() => {
                        this.addEmojiToInput(emojiData, contentDiv === this.recentEmojisContent)
                    }}>
                </div>
            this.setElementContentToEmoji(emojiDiv, emojiData.value)
            if (this.getTonedEmoji(emojiData, EmojiTone.Medium) !== emojiData.value) {
                const toneListener = toneChange.listen((tone) => {
                    this.setElementContentToEmoji(emojiDiv, this.getTonedEmoji(emojiData, tone))
                })
                listenerGroup?.add(toneListener)
            }
            contentDiv.appendChild(emojiDiv)
        }
    }

    private repopulateRecentEmojisContent(emojisData: IEmoji[]): void {
        if (this.recentEmojisContent.parentNode !== null) {
            const recentEmojisContentClone = this.recentEmojisContent.cloneNode(false) as HTMLDivElement
            this.recentEmojisToneListeners.removeAll()
            this.populateSectionContent(recentEmojisContentClone, emojisData, this.recentEmojisToneListeners)
            this.recentEmojisContent.parentNode.replaceChild(recentEmojisContentClone, this.recentEmojisContent)
            this.recentEmojisContent = recentEmojisContentClone
        }
    }

    private createToneSelect(): HTMLDivElement {
        const toneSelectStyle: CSSX.Properties = {
            position: "relative",
            left: "-1px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "0px 0px 3px 3px",
            width: "100%",
            height: "40px",
        }
        const toneSelectContentStyle: CSSX.Properties = {
            position: "relative",
            cssFloat: "right",
            height: "100%",
            right: "17px",
        }
        const toneTextStyle: CSSX.Properties = {
            position: "relative",
            top: "50%",
            transform: "translateY(-50%)",
            cssFloat: "left",
            marginRight: "8px",
        }
        const currentToneContainerStyle: CSSX.Properties = {
            position: "relative",
            width: "32px",
            height: "32px",
            top: "50%",
            transform: "translateY(-50%)",
            borderRadius: "2px",
            cssFloat: "left",
            cursor: "pointer",
        }
        const currentToneStyle: CSSX.Properties = {
            height: "24px",
            width: "24px",
            fontSize: "24px",
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }
        const toneOptionListStyle: CSSX.Properties = {
            fontSize: "24px",
            display: "none",
            height: "100%",
        }
        const toneOptionStyle: CSSX.Properties = {
            height: "24px",
            width: "24px",
            fontSize: "24px",
            position: "relative",
            top: "50%",
            transform: "translateY(-50%)",
            cssFloat: "left",
            marginRight: "8px",
            cursor: "pointer",
        }
        window.setTimeout(() => {
            toneChange.listen((tone) => {
                this.currentTone = tone
                this.currentToneSpan.textContent = `${toneExampleEmoji}${tone}`
                twemoji.parse(this.currentToneSpan, { className: "emojiToneChoice" })
            })
            toneChange.fire(this.getSavedTone())
        }, 0)
        const toneSelect =
            <div style={toneSelectStyle} colorClass="toneSelect">
                <div style={toneSelectContentStyle}>
                    <div style={toneTextStyle} colorClass="sectionTitle">{`${i18n.tone}: `}</div>
                    <div style={currentToneContainerStyle} colorClass="currentToneContainer"
                        ref={(el: HTMLDivElement) => { this.currentToneContainer = el }}
                        onClick={() => {
                            this.showToneOptions()
                        }}>
                        <div style={currentToneStyle}
                            ref={(el: HTMLSpanElement) => { this.currentToneSpan = el }}>
                        </div>
                    </div>
                    <div style={toneOptionListStyle}
                        ref={(el: HTMLDivElement) => { this.toneOptions = el }}>
                    </div>
                </div>
            </div>

        for (const [idx, tone] of toneList.entries()) {
            const style = idx === toneList.length - 1 ? Object.assign(toneOptionStyle, { marginRight: "4px" }) : toneOptionStyle
            const emojiText = `${toneExampleEmoji}${tone}`
            const toneChoice =
                <div style={style}
                    onClick={() => {
                        this.saveTone(tone)
                        this.hideToneOptions()
                    }}>
                    {emojiText}
                </div>
            twemoji.parse(toneChoice, { className: "emojiToneChoice" })
            this.toneOptions.appendChild(toneChoice)
        }

        return toneSelect
    }

    private showToneOptions(): void {
        this.currentToneContainer.style.display = "none"
        this.toneOptions.style.display = "inline-block"
    }

    private hideToneOptions(): void {
        if (hasWellSupportedEmojis()) {
            return
        }
        this.currentToneContainer.style.display = "inline-block"
        this.toneOptions.style.display = "none"
    }

    private saveTone(tone: EmojiTone): void {
        window.localStorage.setItem(toneStorageKey, tone)
        toneChange.fire(tone)
    }

    private getSavedTone(): EmojiTone {
        const savedTone = (window.localStorage.getItem(toneStorageKey) ?? EmojiTone.Default) as EmojiTone
        if (toneList.indexOf(savedTone) === -1) {
            error(`Bad ${toneStorageKey} value`, savedTone)
            return EmojiTone.Default
        }
        return savedTone
    }

    private getTonedEmoji(emojiData: IEmoji, tone: EmojiTone): string {
        const baseEmoji = emojiStringToArray(emojiData.value)[0]
        const splitEmoji = emojiData.value.split(baseEmoji)
        if (splitEmoji.length > 1 && splitEmoji[1][0] === twemoji.convert.fromCodePoint("fe0f")) {
            splitEmoji[1] = splitEmoji[1].slice(1, splitEmoji[1].length)
        }
        const potentialEmoji = splitEmoji.join(baseEmoji + tone)
        return isSingleTwemoji(twemoji.parse(potentialEmoji)) ? potentialEmoji : emojiData.value
    }

    private handleSearchChange(): void {
        const input = this.searchBar.value
        if (input.length <= 1) {
            applyStyles(this.emojiSections, { display: "block" })
            applyStyles(this.searchResults, { display: "none" })
        } else {
            applyStyles(this.emojiSections, { display: "none" })
            applyStyles(this.searchResults, { display: "block" })

            searchEmojis(input).then((emojis) => {
                if (this.searchResults.parentNode !== null) {
                    // Clear results
                    const resultsDivClone = this.searchResults.cloneNode(false)
                    this.searchResults.parentNode.replaceChild(resultsDivClone, this.searchResults)
                    this.searchResults = resultsDivClone as HTMLDivElement
                }

                this.searchResultsToneListeners.removeAll()
                this.populateSectionContent(this.searchResults, emojis, this.searchResultsToneListeners)
            }).catch(() => { })
        }
    }

    private setElementContentToEmoji(el: HTMLElement, emoji: string): void {
        if (!hasWellSupportedEmojis()) {
            let parseResult = twemoji.parse(emoji)

            // If it parses into two+ images, it might be because of "display as emoji" codepoints
            let match = parseResult.match(/<img/g)
            if (match !== null && match.length > 1) {
                const re = new RegExp(twemoji.convert.fromCodePoint("fe0f"), "g")
                emoji = emoji.replace(re, "")
                parseResult = twemoji.parse(emoji)

                // If it still parses into two+ images, the input is malformed
                match = parseResult.match(/<img/g)
                if (match !== null && match.length > 1) {
                    return
                }
            }

            const srcRegex = new RegExp(`src="${twemoji.base}72x72/(.*?).png"`)
            const srcMatch = parseResult.match(srcRegex)
            if (srcMatch !== null) {
                const emojiStyle: CSSX.Properties = {
                    position: "relative",
                    display: "block",
                    top: "50%",
                    left: "50%",
                }
                const srcBasename = srcMatch[1]
                const imgElem = <span style={emojiStyle}/>
                imgElem.classList.add(`sprite-twemoji-${srcBasename}`) // sprite offset css
                imgElem.classList.add("sprite-twemoji") // css for resizing the sprite down from 72x72

                while (el.firstChild !== null) {
                    el.removeChild(el.firstChild)
                }
                el.appendChild(imgElem)
            } else {
                // fallback to unicode rendering if twemoji parsing fails, style so that unicode emoji fits with twemoji
                // grid
                const emojiStyle: CSSX.Properties = {
                    lineHeight: el.style.height,
                    width: "inherit",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                }
                el.appendChild(<span style={emojiStyle}>{emoji}</span>)
            }
        } else {
            el.innerText = emoji
        }
    }

    private addEmojiToInput(emojiData: IEmoji, fromRecents: boolean): void {
        const emoji = this.getTonedEmoji(emojiData, this.currentTone)
        const customInput = this.getCurrentChatInput()
        if (customInput !== undefined) {
            if (hasWellSupportedEmojis()) {
                customInput.insertText(emojiData.value)
            } else {
                customInput.insertText(emoji)
            }
            emojiAdded.fire(undefined)
            addPageAction("emojiAdded", { name: emojiData.name, value: emojiData.value })
            const recentEmojis = recordRecentEmoji(emojiData)
            if (fromRecents) {
                this.recentEmojisRebuildData = recentEmojis
            } else {
                this.repopulateRecentEmojisContent(recentEmojis)
            }
        }
    }

    private onClickUploadLink(): boolean {
        window["use_uploaded_emoticon"] = (slug: string) => {
            const customInput = this.getCurrentChatInput()
            if (customInput !== undefined) {
                const currentInputValue = customInput.getText()
                if (currentInputValue === "" || /\s/.test(currentInputValue[currentInputValue.length - 1])) {
                    customInput.insertText(slug, true)
                } else {
                    customInput.insertText(` ${slug}`, true)
                }
            }
        }
        safeWindowOpen("/emoticons/", "_blank", "height=615, width=850")
        this.hide()
        return false
    }

    public show(clickedElement: HTMLElement): void {
        const fullscreenEl = fullscreenElement()
        if (isFullscreen() && fullscreenEl !== undefined) {
            fullscreenEl.appendChild(this.overlay)
            fullscreenEl.appendChild(this.element)
        } else {
            document.body.appendChild(this.overlay)
            document.body.appendChild(this.element)
        }
        this.reposition(clickedElement)
        this.showOverlay()
        this.element.style.visibility = ""
        this.isVisible = true
        addPageAction("emojiSelectorOpened")
    }

    public hide(): void {
        if (this.element.style.visibility === "hidden") {
            return
        }
        if (this.element.parentElement !== null) {
            this.element.parentElement.removeChild(this.element)
        }
        if (this.overlay.parentElement !== null) {
            this.overlay.parentElement.removeChild(this.overlay)
        }
        this.hideOverlay()
        this.hideToneOptions()
        this.element.style.visibility = "hidden"
        this.isVisible = false
        this.searchBar.value = ""
        this.handleSearchChange()
        const customInput = this.getCurrentChatInput()
        if (customInput !== undefined) {
            customInput.focus(true)
        }
        if (this.recentEmojisRebuildData !== undefined) {
            this.repopulateRecentEmojisContent(this.recentEmojisRebuildData)
            this.recentEmojisRebuildData = undefined
        }
    }

    private reposition(targetElement: HTMLElement): void {
        const targetRect = targetElement.getBoundingClientRect()
        let xOffset = window.pageXOffset
        let yOffset = window.pageYOffset
        if (isFullscreen()) {
            xOffset = 0
            yOffset = 0
        }
        let left = targetRect.left + xOffset - this.element.offsetWidth + 35
        let divotLeft = this.element.offsetWidth - 37

        if (left < 0) {
            left = targetRect.left + xOffset - 25
            divotLeft = 23
        }
        this.element.style.top = `${targetRect.top + yOffset - this.element.offsetHeight - 45}px`
        if (hasWellSupportedEmojis()) {
            this.element.style.top = `${targetRect.top + yOffset - this.element.offsetHeight - 8}px`
        }
        this.element.style.left = `${left}px`
        this.bottomDivot.style.left = `${divotLeft}px`
    }

    public isShown(): boolean {
        return this.isVisible
    }
}

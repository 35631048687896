import { addColorClass, removeColorClass } from "../../cb/colorClasses"
import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { ChatButtonHolder, ChatButtonType } from "../../cb/ui/chatButtonHolder"
import { BaseTab, TabId } from "../../cb/ui/tabs"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { ChatSettings } from "../chatSettings"
import { applyStyles } from "../DOMutils"
import { addPageAction } from "../newrelic"
import { styleTransition } from "../safeStyle"
import { cogSvg } from "../svgElement"
import { createInputField, createInputForm, maxInputChat, maxInputPm, SendButtonVariant } from "../theatermodelib/chatTabContents"
import { i18n } from "../translation"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import {
    createInputDiv,
    createTipButton,
    fvmButtonGap,
} from "./chatTabContents"
import type { IChatWindowTab } from "../../cb/ui/iChatTab"
import type { CustomInput } from "../customInput"
import type { IVideoModeChangeNotification } from "../videoModeHandler"

export class ChatSettingsTab extends BaseTab implements IChatWindowTab {
    private inputField: CustomInput
    private inputDiv: HTMLDivElement
    private buttonHolder: ChatButtonHolder
    private tipButton: HTMLElement
    private chatSettings = new ChatSettings()
    private settingInput = false

    constructor() {
        super()

        this.element.id = "FVChatSettingsTab"
        this.element.style.fontSize = "12px"
        this.element.style.fontFamily = "Tahoma, Arial, Helvetica, sans-serif"
        this.element.style.display = "flex"
        this.element.style.flexDirection = "column"

        applyStyles(this.chatSettings, {
            flex: 1,
            height: "",
        })
        this.prependChild(this.chatSettings)
        removeColorClass(this.chatSettings.manageIgnoredUsersLink, "link")
        addColorClass(this.chatSettings.manageIgnoredUsersLink, "manage-ignored-link")

        this.inputDiv = createInputDiv()
        const chatForm = createInputForm()
        this.inputField = createInputField(() => false, Math.max(maxInputChat, maxInputPm))
        const switchToChatTab = () => {
            if (!this.settingInput) {
                this.chatSettings.hideModals()
                if (this.parent !== undefined) {
                    this.parent.changeToFirstTab()
                }
            }
        }
        addEventListenerPoly("click", this.inputField.element, switchToChatTab)
        addEventListenerPoly("focus", this.inputField.element, switchToChatTab)
        const sendButtonRoot = document.createElement("span")
        const SendButton = ReactComponentRegistry.get("SendButton")
        new SendButton({
            "onClick": switchToChatTab,
            "isPm": false,
            "variant": SendButtonVariant.DraggableCanvas,
        }, sendButtonRoot)
        this.buttonHolder = new ChatButtonHolder({ columnGap: `${fvmButtonGap}px` })
        this.buttonHolder.addButton(sendButtonRoot, ChatButtonType.Text)
        this.tipButton = createTipButton()
        this.buttonHolder.addButton(this.tipButton, ChatButtonType.Text)
        chatForm.appendChild(this.inputField.element)
        this.inputDiv.appendChild(chatForm)
        this.inputDiv.appendChild(this.buttonHolder.element)
        this.element.appendChild(this.inputDiv)

        const saveNotice = document.createElement("div")
        saveNotice.innerText = i18n.chatSettingsSavedText
        saveNotice.style.position = "absolute"
        saveNotice.style.top = "10px"
        saveNotice.style.right = "10px"
        saveNotice.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        saveNotice.style.color = "#000000"
        saveNotice.style.backgroundColor = "#d8deea"
        saveNotice.style.border = "1px solid #acacac"
        saveNotice.style.borderRadius = "4px"
        saveNotice.style.padding = "8px 12px"
        saveNotice.style.visibility = "hidden"
        this.element.appendChild(saveNotice)

        videoModeHandler.changeVideoMode.listen((videoModeChangeNotification: IVideoModeChangeNotification) => {
            if (videoModeHandler.getVideoMode() !== VideoMode.Split) {
                this.prependChild(this.chatSettings)
            }
        })

        let saveNoticeTimeout: number
        this.chatSettings.chatSettingsSavedRequest.listen(() => {
            clearTimeout(saveNoticeTimeout)
            styleTransition(saveNotice, "250ms")
            saveNotice.style.visibility = "visible"
            saveNoticeTimeout = window.setTimeout(() => {
                styleTransition(saveNotice, "1000ms")
                saveNotice.style.visibility = "hidden"
            }, 3000)
        })
    }

    public showElement(): void {
        super.showElement("flex")
    }

    protected getHandleStyle(): CSSX.Properties {
        const style: CSSX.Properties = {
            ...super.getHandleStyle(),
            minWidth: "16px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            height: "12px",
        }
        return style
    }

    public getChatSettings(): ChatSettings {
        return this.chatSettings
    }

    protected getTabHandleContent(): Node[] {
        return [cogSvg(0)]
    }

    public getTabId(): TabId {
        return TabId.SettingsFvm
    }

    public focusCurrentChatInput(): void {
    }

    public blurCurrentChatInput(): void {
        this.inputField.blur()
    }

    public isInputFocused(): boolean {
        return document.activeElement === this.inputField.element
    }

    public scrollToBottom(): void {
    }

    public getChatInputField(): CustomInput {
        return this.inputField
    }

    protected tabHandleClicked(_: MouseEvent): void {
        super.tabHandleClicked(_)
        addPageAction("FocusTab", { "location": "chatSettings" })
    }
}

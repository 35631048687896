import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { TransparentCheckbox } from "../../cb/components/toggle"
import { pageContext } from "../../cb/interfaces/context"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { Component } from "../defui/component"
import { userCategoryToAbbrev } from "../genders"
import { MoreRoomIterator } from "../moreRoomsIterator"
import { addPageAction } from "../newrelic"
import { i18n } from "../translation"
import { Room, roomImageHeightRatio, roomPadding } from "./room"
import type { IRoomInfo } from "../../cb/components/roomlist/IRoomInfo"

const GENDER_ICON_MAP: Record<string, string> = {
    Couple: `${STATIC_URL}iconCouple.png`,
    Female: `${STATIC_URL}iconFemale.png`,
    Male: `${STATIC_URL}iconMale.png`,
    Trans: `${STATIC_URL}iconTrans.png`,
}

export class DesktopMoreRoomsList extends Component {
    private genderCheckboxesDiv: HTMLDivElement
    private genderCheckboxes: TransparentCheckbox[] = []
    private genderLabels: HTMLSpanElement[] = []
    private areGenderLabelsDisplayed = true
    private loadingDiv: HTMLDivElement
    private loadingDivIsShowing = false
    private localStorageGenderKey = "genders"
    private selectedGenders: string[] = []
    private roomIterator = new MoreRoomIterator(10)
    private intervalUpdate: number

    constructor() {
        super()
        this.element.dataset.testid = "more-rooms-list"
        this.element.style.fontSize = "12px"
        this.element.style.overflowY = "scroll" // prevent shifting of gender boxes when no rooms are visible
        addEventListenerPoly("scroll", this.element, () => {
            this.loadMoreRoomsIfNearBottom()
        })
        if (isLocalStorageSupported()) {
            const storedGenders = window.localStorage.getItem(this.localStorageGenderKey)
            if (storedGenders !== null) {
                this.selectedGenders = JSON.parse(storedGenders)
                if (pageContext.current.loggedInUser === undefined) {
                    const followedIndex = this.selectedGenders.indexOf(userCategoryToAbbrev("followed"))
                    if (followedIndex >= 0) {
                        this.selectedGenders.splice(followedIndex, 1)
                    }
                }
            }
        }
        this.buildGenderCheckboxesDiv()
        this.loadingDiv = document.createElement("div")
        addColorClass(this.loadingDiv, colorClass.defaultTooltipColor)
        this.loadingDiv.innerText = `${i18n.loadingText}...`
        this.loadingDiv.style.margin = `${roomPadding}px`
        this.resetAndLoad()

        const refreshButton = document.createElement("p")
        addColorClass(refreshButton, colorClass.defaultTooltipColor)
        refreshButton.style.cursor = "pointer"
        refreshButton.style.padding = "0"
        refreshButton.style.margin = "4px 0"
        refreshButton.dataset.testid = "refresh-btn"
        refreshButton.onclick = () => {
            this.resetAndLoad()
        }
        addEventListenerPoly("mouseenter", refreshButton, () => {
            refreshButton.style.textDecoration = "underline"
        })
        addEventListenerPoly("mouseleave", refreshButton, () => {
            refreshButton.style.textDecoration = ""
        })
        refreshButton.innerText = i18n.refreshRoomsText
        refreshButton.style.textAlign = "left"
        this.genderCheckboxesDiv.appendChild(refreshButton)

        this.intervalUpdate = window.setInterval(() => {
            for (const room of this.children()) {
                room.reloadImage()
            }
        }, 30000)
    }

    close(): void {
        clearInterval(this.intervalUpdate)
        this.roomIterator.dispose()
    }

    private appendRoomsToDiv(roomInfoList: IRoomInfo[]): void {
        if (this.loadingDivIsShowing) {
            this.element.removeChild(this.loadingDiv)
            this.loadingDivIsShowing = false
        }
        for (const roomInfo of roomInfoList) {
            this.addChild(new Room(roomInfo.room, this.children().length + 1, roomInfo.sourceInfo))
        }
    }

    children(): Room[] {
        return super.children() as Room[]
    }

    private buildGenderCheckbox(gender: string, title: string): void {
        const isChecked = this.selectedGenders.indexOf(userCategoryToAbbrev(gender.toLowerCase())) > -1
        const checkbox = new TransparentCheckbox(12, isChecked)
        checkbox.setOnChange(() => {
            this.handleGenderChange()
        })
        checkbox.setName(gender.toLowerCase())
        checkbox.setTitle(gender)

        if (this.genderLabels.length > 0) {
            checkbox.element.style.marginLeft = "12px"
        }
        checkbox.element.style.top = "2px"

        checkbox.element.dataset.testid = `${gender.toLowerCase()}-checkbox`
        this.genderCheckboxesDiv.appendChild(checkbox.element)
        this.genderCheckboxes.push(checkbox)

        const icon = document.createElement("img")
        icon.src = GENDER_ICON_MAP[gender]
        icon.style.position = "relative"
        icon.style.top = "2px"
        icon.style.cursor = "pointer"
        icon.style.width = "16px"
        icon.style.height = "16px"
        icon.style.marginLeft = "3px"
        icon.title = title
        icon.onclick = () => {
            checkbox.setChecked(!checkbox.isChecked())
            this.handleGenderChange()
        }
        this.genderCheckboxesDiv.appendChild(icon)

        const label = document.createElement("span")
        addColorClass(label, colorClass.defaultTooltipColor)
        label.innerText = title
        label.style.position = "relative"
        label.style.top = "1px"
        label.style.marginLeft = "4px"
        label.style.cursor = "pointer"
        label.onclick = () => {
            checkbox.setChecked(!checkbox.isChecked())
            this.handleGenderChange()
        }
        this.genderCheckboxesDiv.appendChild(label)
        this.genderLabels.push(label)
    }

    private buildGenderCheckboxesDiv(): void {
        this.genderCheckboxesDiv = document.createElement("div")
        this.genderCheckboxesDiv.style.margin = "2px 2px 0 2px"
        this.genderCheckboxesDiv.style.whiteSpace = "nowrap"
        this.genderCheckboxesDiv.style.textAlign = "center"
        this.element.appendChild(this.genderCheckboxesDiv)

        this.buildGenderCheckbox("Female", i18n.womenText)
        this.buildGenderCheckbox("Male", i18n.menText)
        this.buildGenderCheckbox("Couple", i18n.couplesText)
        this.buildGenderCheckbox("Trans", i18n.transText)
    }

    private handleGenderChange(): void {
        this.selectedGenders = []
        for (const checkbox of this.genderCheckboxes) {
            if (checkbox.isChecked()) {
                this.selectedGenders.push(userCategoryToAbbrev(checkbox.getName()))
            }
        }
        addPageAction("ChangeRoomListGender", { "selectedGenders": this.selectedGenders.toString() })
        if (isLocalStorageSupported()) {
            if (this.selectedGenders.length === 0) {
                window.localStorage.removeItem(this.localStorageGenderKey)
            } else {
                window.localStorage.setItem(this.localStorageGenderKey, JSON.stringify(this.selectedGenders))
            }
        }
        this.resetAndLoad()
    }

    private roomWidth(): number {
        return this.element.clientWidth - roomPadding * 2
    }

    private roomHeight(): number {
        return this.roomWidth() * roomImageHeightRatio
    }

    repositionChildren(): void {
        super.repositionChildren()
        const roomWidth = this.roomWidth()
        for (const child of this.children()) {
            child.element.style.width = `${roomWidth}px`
            child.element.style.height = `${this.roomHeight()}px`
        }
        this.genderCheckboxesDiv.style.width = `${roomWidth}px`
        if (!this.areGenderLabelsDisplayed && this.genderCheckboxesDiv.scrollWidth <= roomWidth) {
            for (const label of this.genderLabels) {
                label.style.display = "inline-block"
            }
            this.areGenderLabelsDisplayed = true
        }
        if (this.areGenderLabelsDisplayed && this.genderCheckboxesDiv.scrollWidth > roomWidth) {
            for (const label of this.genderLabels) {
                label.style.display = "none"
            }
            this.areGenderLabelsDisplayed = false
        }
        this.loadMoreRoomsIfNearBottom()
    }

    private resetAndLoad(): void {
        this.removeAllChildren()
        this.loadingDivIsShowing = true
        this.element.appendChild(this.loadingDiv)
        this.loadFromNewIterator()
        this.repositionChildrenRecursive()
    }

    private isLoadingRooms = false

    private loadFromNewIterator(): void {
        this.roomIterator.setGenders(this.selectedGenders)
        this.roomIterator.next().then((rooms) => {
            this.insertLoadedRooms(rooms)
        }).catch((err) => {
            this.isLoadingRooms = false
            error("getNewIterator", err)
        })
    }

    private loadFromCurrentIterator(): void {
        if (this.isLoadingRooms) {
            return
        }
        this.isLoadingRooms = true
        this.roomIterator.next().then((rooms) => {
            this.insertLoadedRooms(rooms)
        }).catch((err) => {
            this.isLoadingRooms = false
            error("loadMoreRooms", err)
        })
    }

    private insertLoadedRooms(roomInfoList: IRoomInfo[]): void {
        if (roomInfoList.length > 0) {
            this.finishInsertLoadedRooms(roomInfoList)
            this.repositionChildrenRecursive()
            this.isLoadingRooms = false
            this.loadMoreRoomsIfNearBottom()
        } else {
            this.isLoadingRooms = false
        }
    }

    private finishInsertLoadedRooms(rooms: IRoomInfo[]): void {
        this.removeRoomsScrolledPastTop()
        this.appendRoomsToDiv(rooms)
    }

    private removeRoomsScrolledPastTop(): void {
        const roomHeight = this.roomHeight()
        let scrollTop = this.element.scrollTop
        const removeList: Room[] = []
        for (const room of this.children()) {
            if (room.element.offsetTop - scrollTop < roomHeight * -15) {
                removeList.push(room)
            } else {
                break
            }
        }
        for (const room of removeList) {
            scrollTop -= room.element.offsetHeight
            this.removeChild(room)
        }
        this.element.scrollTop = scrollTop
    }

    private loadMoreRoomsIfNearBottom(): void {
        if (this.element.scrollTop + this.roomHeight() * 5 >= this.element.scrollHeight - this.element.offsetHeight) {
            this.loadFromCurrentIterator()
        }
    }
}

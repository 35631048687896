import { getBanID } from "../../cb/api/chatBansAndSilences"
import { dmsEnabled, getUnreadPms } from "../../cb/api/pm"
import { DesktopDMChatLink } from "../../cb/components/pm/chatLinks"
import { createDmWindowRequest } from "../../cb/components/pm/dmWindowsManager"
import { allPmsRead, directMessage, privateMessage } from "../../cb/components/pm/userActionEvents"
import { MentionUserList } from "../../cb/components/userMentions/MentionUserList"
import { pageContext } from "../../cb/interfaces/context"
import { BaseTabsContainer } from "../../cb/ui/tabs"
import { goToSettingsTab } from "../chatSettingsUtil"
import { createUndoOptions } from "../chatUndoOptions"
import { roomCleanup, roomLoaded } from "../context"
import { ConversationNotifier } from "../conversationNotifier"
import { DmNotifier } from "../dmNotifier"
import { ListenerGroup } from "../events"
import { exitFullscreen } from "../fullscreen"
import { ignoreCatch } from "../promiseUtils"
import { OverlayRoomNotice } from "../roomNotice"
import { RoomNoticeDeclutterer } from "../roomNoticeDeclutterer"
import { isCurrentUserRoomOwner } from "../roomUtil"
import { createRoomPhotoMessage } from "../theatermodelib/messageToDOM"
import { i18n } from "../translation"
import { appDebuggingToggled, mentionUser } from "../userActionEvents"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { ChatSettingsTab } from "./chatSettingsTab"
import { ChatTab } from "./chatTab"
import {
    createLogMessage,
    createPMChatLinkMessage,
    createRoomMessage,
    createShortcodeMessage,
} from "./messageToDOM"
import { PmTab } from "./pmTab"
import type { IPMAnnouncement } from "../../cb/components/pm/chatLinks"
import type { IChatTabContainer, IChatWindowTab } from "../../cb/ui/iChatTab"
import type { BaseTab, CollapsibleTab } from "../../cb/ui/tabs"
import type { IRoomContext } from "../context"
import type {
    IBanSilenceInfo,
    IPrivateMessage,
    IPushPrivateMessage,
    IRemoveMessagesNotification,
    IRoomMessage,
    IRoomNotice,
} from "../messageInterfaces"
import type { RoomNotice } from "../roomNotice"

export class ChatTabContainer extends BaseTabsContainer implements IChatTabContainer {
    public chatTab: ChatTab
    private pmTab: PmTab
    private settingsTab: ChatSettingsTab
    private readonly perRoomListeners: ListenerGroup
    private inPrivateRoom = false
    private privateShowUser = ""
    private room = ""
    private conversationNotifier?: ConversationNotifier
    private roomNoticeDeclutterer: RoomNoticeDeclutterer

    constructor() {
        super()
        this.perRoomListeners = new ListenerGroup()

        this.element.style.overflow = ""

        this.chatTab = this.addTab(new ChatTab())
        this.pmTab = this.addTab(new PmTab(() => {
            this.changeToTab(this.chatTab)
        }))
        this.settingsTab = this.addTab(new ChatSettingsTab())
        this.tabHandlesRow.element.dataset["paction"] = "TheaterChat"
        this.settingsTab.handle.element.dataset["pactionName"] = "Settings"
        this.changeToTab(this.chatTab)

        this.tabHandlesRow.element.style.padding = "1px 2px 0 0"

        this.initNoticeDeclutterer()

        roomLoaded.listen((context) => {
            this.room = context.chatConnection.room()
            this.changeToTab(this.chatTab)

            context.chatConnection.event.roomShortcode.listen((m) => {
                const shortcodeMsgDiv = createShortcodeMessage(m)
                this.chatTab.chatTabContents.appendMessageDiv(shortcodeMsgDiv)
            })
            context.chatConnection.event.roomMessage.listen((m) => {
                this.newChatMessage(m)
            })
            context.chatConnection.event.roomNotice.listen((m) => {
                this.newRoomNotice(m)
            })
            context.chatConnection.event.removeMessages.listen((removeMessages: IRemoveMessagesNotification) => {
                this.chatTab.handleRemoveMessages(removeMessages.username)
                this.chatTab.chatTabContents.removeMessagesForUser(removeMessages.username)
                this.pmTab.closePMSession(removeMessages.username)
            })
            context.chatConnection.event.appDebugLog.listen((m) => {
                if (context.chatConnection.isAppDebuggingEnabled()) {
                    this.chatTab.chatTabContents.appendMessageDiv(createLogMessage(`DEBUG: ${m}`))
                }
            })
            context.chatConnection.event.onBanSilence.listen((evt) => {
                this.handleBanSilence(evt, context)  // eslint-disable-line @typescript-eslint/no-floating-promises
            })
            context.chatConnection.event.statusChange.listen(() => {
                this.inPrivateRoom = context.chatConnection.inPrivateRoom()
                this.privateShowUser = context.chatConnection.getPrivateShowUser()
                this.loadUnreadPmsForPrivateShow()
            })

            this.conversationNotifier = new ConversationNotifier(context.chatConnection)

            if (pageContext.current.mergePmDm) {
                directMessage.listen((m: IPushPrivateMessage) => {
                    if (this.conversationNotifier?.shouldNotify(m.fromUser.username) === true) {
                        const msgDiv = this.conversationNotifier.createChatLinkMessage(m.fromUser.username)
                        this.chatTab.chatTabContents.appendMessageDiv(msgDiv)
                        this.conversationNotifier.preventNotifications(m.otherUsername)
                    }
                }).addTo(this.perRoomListeners)
            }

            this.settingsTab.getChatSettings().hideModals()
        })

        if (!pageContext.current.mergePmDm) {
            const dmNotifier = new DmNotifier()
            if (dmsEnabled()) {
                directMessage.listen((m: IPushPrivateMessage) => {
                    if (dmNotifier.shouldNotify(m.fromUser.username)) {
                        const msg = i18n.newDirectMessageNotice(m.otherUsername)
                        const chatLink = new DesktopDMChatLink({
                            onClick: () => {
                                // Ensure we're not in IFS since the DM window isn't visible in IFS
                                exitFullscreen()
                                createDmWindowRequest.fire(m.otherUsername)
                            },
                        })
                        const msgDiv = createPMChatLinkMessage(msg, chatLink.openConversationElement)
                        this.chatTab.chatTabContents.appendMessageDiv(msgDiv)
                        dmNotifier.preventNotifications(m.otherUsername)
                    }
                })
            }
        }

        if (!pageContext.current.mergePmDm) {
            this.pmTab.otherUserInitiatedPm.listen((pmAnnouncement) => {
                if (this.conversationNotifier?.shouldNotify(pmAnnouncement.username) === true) {
                    this.newPmChatLink(pmAnnouncement)
                    this.conversationNotifier.preventNotifications(pmAnnouncement.username)
                }
            })
        }

        roomCleanup.listen(() => {
            this.perRoomListeners.removeAll()
            this.conversationNotifier?.dispose()
            this.roomNoticeDeclutterer.onRoomCleanup()
        })

        appDebuggingToggled.listen((enabled) => {
            if (enabled) {
                this.chatTab.chatTabContents.appendMessageDiv(
                    createLogMessage("Debug mode enabled. Type /debug again to disable."))
            } else {
                this.chatTab.chatTabContents.appendMessageDiv(createLogMessage("Debug mode disabled."))
            }
        })

        mentionUser.listen(username => {
            if (videoModeHandler.getVideoMode() !== VideoMode.Split) {
                this.changeToTab(this.chatTab)
                this.chatTab.appendInputText(`@${username} `)
            }
        })

        allPmsRead.listen((username) => {
            if (
                this.inPrivateRoom
                && (username === this.privateShowUser || username === this.room)
            ) {
                this.chatTab.resetNumUnread()
            }
        })

        privateMessage.listen((msg: IPrivateMessage) => {
            if (msg.isPrivateShowMessage === true) {
                this.newPrivateShowMessage(msg)
            }
        })

        goToSettingsTab.listen(() => {
            this.changeToTab(this.settingsTab)
        })
    }

    private initNoticeDeclutterer(): void {
        this.roomNoticeDeclutterer = new RoomNoticeDeclutterer({
            appendNotice: (notice: RoomNotice, countsForUnread: boolean) => this.chatTab.chatTabContents.appendMessageDiv(notice.element, countsForUnread),
            appendSentinelDiv: (div: HTMLDivElement) => this.chatTab.chatTabContents.element.appendChild(div),
        })
    }

    private async handleBanSilence(m: IBanSilenceInfo, context: IRoomContext): Promise<void> {
        if (isCurrentUserRoomOwner() || m.silencer === context.dossier.userName) {
            const banId = await getBanID({
                bannedUser: m.silenced,
                createdBy: m.silencer,
                isSilence: !m.isBan,
                roomUser: context.dossier.room,
            })

            const logFunction = (msg: string) => {
                this.showLogMsg(msg)
            }
            const divOptions = createUndoOptions(this.chatTab.chatTabContents, m, banId, context, logFunction)
            this.chatTab.chatTabContents.appendMessageDiv(divOptions)
        }
    }

    private showLogMsg(msg: string): void {
        this.chatTab.chatTabContents.appendMessageDiv(createLogMessage(msg))
    }

    private shouldIncrementUnread(m: IRoomMessage): boolean {
        const isPrivateShowMessage = m.isPrivateShowMessage === true
        const isFromSelf = m.fromUser.username === pageContext.current.loggedInUser?.username

        return !isPrivateShowMessage
            || !isFromSelf && !this.isPmSessionActive(m.fromUser.username)
    }

    private newChatMessage(m: IRoomMessage): void {
        if (this.shouldIncrementUnread(m)) {
            this.chatTab.possiblyIncrementUnread()
        }

        MentionUserList.getInstance().addRecentUser(m.fromUser)
        const div = createRoomMessage(m)
        this.chatTab.chatTabContents.appendMessageDiv(div, this.shouldIncrementUnread(m))
    }

    private newPrivateShowMessage(m: IPrivateMessage): void {
        this.newChatMessage(m)
        const photoMessage = createRoomPhotoMessage(m)

        if (photoMessage !== undefined) {
            this.chatTab.chatTabContents.appendMessageDiv(photoMessage, false)
        }

        this.maybeMarkPrivateShowPmsRead()
    }

    private newRoomNotice(roomNoticeData: IRoomNotice): void {
        const roomNotice = new OverlayRoomNotice({
            roomNoticeData,
            isChatScrolledToBottom: () => !this.chatTab.chatTabContents.isScrolledUp(),
            scrollChatToBottom: () => this.chatTab.chatTabContents.debouncedScrollToBottom(),
            getChatScrollTop: () => this.chatTab.chatTabContents.getScrollTop(),
            setChatScrollTop: (top: number) => this.chatTab.chatTabContents.setScrollTop(top),
        })

        this.roomNoticeDeclutterer.addRoomNotice(roomNotice)
        if (roomNoticeData.showInPrivateMessage) {
            this.pmTab.possiblyAppendMessageDiv(new OverlayRoomNotice({ roomNoticeData, neverCollapse: true }).element)
        }
    }

    private newPmChatLink(pmAnnouncement: IPMAnnouncement): void {
        const msg = i18n.newPrivateMessageNotice(pmAnnouncement.username)
        const msgDiv = createPMChatLinkMessage(msg, pmAnnouncement.PMChatLink.openConversationElement)
        this.chatTab.chatTabContents.appendMessageDiv(msgDiv)
    }

    private loadUnreadPmsForPrivateShow(): void {
        if (!this.inPrivateRoom) {
            return
        }

        const otherUser = isCurrentUserRoomOwner() ? this.privateShowUser : this.room
        getUnreadPms(otherUser, this.room, "0").then((unreadMessages) => {
            unreadMessages.forEach((message) => this.newPrivateShowMessage(message))
        }).catch(ignoreCatch)
    }

    private isPmSessionActive(username: string): boolean {
        return this.currentTab === this.pmTab && this.pmTab.getSession(username)?.isActive() === true
    }

    private maybeMarkPrivateShowPmsRead(): void {
        if (
            this.chatTab.active
            && this.inPrivateRoom
            && videoModeHandler.getVideoMode() !== VideoMode.Split
        ) {
            allPmsRead.fire(isCurrentUserRoomOwner() ? this.privateShowUser : this.room)
        }
    }

    public changeToTab(t: BaseTab): void {
        super.changeToTab(t)
        this.maybeMarkPrivateShowPmsRead()
    }

    public repositionChildren(): void {
        this.window.style.height = `${this.element.clientHeight - this.tabHandlesRow.element.offsetHeight}px`
    }

    public getCurrentTab(): IChatWindowTab {
        return super.getCurrentTab() as IChatWindowTab
    }

    public children(): IChatWindowTab[] {
        return super.children() as IChatWindowTab[]
    }

    public cycleToNextWindow(): void {
        this.cycleToWindow("next")
    }

    public cycleToPrevWindow(): void {
        this.cycleToWindow("prev")
    }

    private cycleToWindow(direction: "next" | "prev"): void {
        const cycle = direction === "next" ? () => this.pmTab.showNextPMSession() : () => this.pmTab.showPrevPMSession()
        const resetCycle = direction === "next" ? () => this.pmTab.resetNextQueue() : () => this.pmTab.resetPrevQueue()
        this.settingsTab.getChatSettings().hideModals()
        if (super.getCurrentTab() === this.chatTab) {
            this.changeToTab(this.pmTab)
            resetCycle()
            cycle()
        } else {
            if (!cycle()) {
                this.changeToTab(this.chatTab)
            }
        }
        this.repositionChildrenRecursive()
    }

    public closeCurrentPMSession(): void {
        if (super.getCurrentTab() === this.pmTab) {
            this.pmTab.closeCurrentPMSession()
        }
    }

    public getHandleStyle(t: CollapsibleTab): CSSX.Properties {
        const style = super.getHandleStyle(t)
        return {
            ...style,
            lineHeight: "12px",
        }
    }
}

import { ArgJSONMap } from "@multimediallc/web-utils"
import { addColorClass, colorClass } from "../cb/colorClasses"
import { ReactComponentRegistry } from "../cb/components/ReactRegistry"
import { modalAlert } from "./alerts"
import { normalizeResource, postCb } from "./api"
import { roomLoaded } from "./context"
import { Component } from "./defui/component"
import { applyStyles } from "./DOMutils"
import { EventRouter } from "./events"
import { i18n } from "./translation"

let roomName: string
// eslint-disable-next-line @multimediallc/no-global-listener
roomLoaded.listen((context) => {
    roomName = context.chatConnection.room()
})

export interface ISendReportResponse {
    success: boolean
    html?: string
}

const formGap = 8

const CATEGORIES = {
    "---": "---",
    "underage": i18n.reportAbuseCatUnderage,
    "advertising": i18n.reportAbuseCatAdvertising,
    "abusive": i18n.reportAbuseCatAbusive,
    "intoxicated": i18n.reportAbuseCatIntoxicated,
    "large toy": i18n.reportAbuseCatLarge,
    "offline payments": i18n.reportAbuseCatOffline,
    "public broadcast": i18n.reportAbuseCatPublic,
    "service uniform": i18n.reportAbuseCatUniform,
    "sleeping": i18n.reportAbuseCatSleeping,
    "gender": i18n.reportAbuseCatGender,
    "other": i18n.reportAbuseCatOther,
}

export class AbuseReport extends Component {
    private categoryInput: HTMLSelectElement
    private commentsInput: HTMLTextAreaElement
    private categoryLabel: HTMLLabelElement
    private buttonDiv: HTMLDivElement
    private abuseTermsDiv: HTMLDivElement

    closeReportAbuseRequest = new EventRouter<undefined>("closeReportAbuseRequest")

    constructor() {
        super()

        this.element.id = "AbuseReport"
        this.element.style.fontFamily = "UbuntuBold, Arial, Helvetica, sans-serif"
        this.element.style.fontSize = "11px"
        this.element.style.position = "relative"
        this.element.style.height = ""

        const form = document.createElement("form")
        applyStyles(form, {
            display: "flex",
            flexDirection: "column",
            padding: `${formGap}px`,
            height: "100%",
            boxSizing: "border-box",
        })

        const categoryDiv = document.createElement("div")
        addColorClass(categoryDiv, colorClass.defaultColor)
        this.categoryLabel = document.createElement("label")
        applyStyles(this.categoryLabel, {
            display: "block",
            margin: "0",
            marginBottom: `${formGap}px`,
        })
        this.categoryLabel.htmlFor = "abuse-category"
        this.categoryLabel.innerText = i18n.chooseCategoryText
        categoryDiv.appendChild(this.categoryLabel)
        this.categoryInput = document.createElement("select")
        addColorClass(this.categoryInput, "abuseInput")
        applyStyles(this.categoryInput, {
            width: "80%",
            margin: "0",
            marginBottom: `${formGap}px`,
            boxSizing: "border-box",
            borderRadius: "4px",
        })
        this.categoryInput.id = "abuse-category"
        for (const [category, value] of Object.entries(CATEGORIES)) {
            const categoryOption = document.createElement("option")
            categoryOption.value = category
            categoryOption.innerText = value
            this.categoryInput.appendChild(categoryOption)
        }
        categoryDiv.appendChild(this.categoryInput)
        form.appendChild(categoryDiv)

        const commentsDiv = document.createElement("div")
        applyStyles(commentsDiv, {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            margin: "0",
            marginBottom: `${formGap}px`,
        })
        const commentsLabel = document.createElement("label")
        applyStyles(commentsLabel, {
            display: "block",
            margin: "0",
            marginBottom: `${formGap}px`,
        })
        commentsLabel.htmlFor = "abuse-comments"
        commentsLabel.innerText = i18n.reportAbuseAdditionalComments
        commentsDiv.appendChild(commentsLabel)
        this.commentsInput = document.createElement("textarea")
        addColorClass(this.commentsInput, "abuseInput")
        applyStyles(this.commentsInput, {
            width: "100%",
            resize: "none",
            padding: "4px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
            boxSizing: "border-box",
            display: "block",
            flex: "1",
            margin: "0",
        })
        this.commentsInput.id = "abuse-comments"
        this.commentsInput.maxLength = 255
        commentsDiv.appendChild(this.commentsInput)
        form.appendChild(commentsDiv)

        this.abuseTermsDiv = document.createElement("div")
        applyStyles(this.abuseTermsDiv, {
            marginBottom: `${formGap}px`,
            fontFamily: "Ubuntu, Arial, Helvetica, sans-serif",
            fontSize: "8px",
        })
        const termsText = document.createElement("span")
        termsText.textContent = `${i18n.reportAbuseTextTerms} `
        const termsLink = document.createElement("a")
        addColorClass(termsLink, colorClass.hrefColor)
        termsLink.href = normalizeResource("/terms/#room-reports")
        termsLink.textContent = "Terms"
        this.abuseTermsDiv.appendChild(termsText)
        this.abuseTermsDiv.appendChild(termsLink)
        form.appendChild(this.abuseTermsDiv)

        this.buttonDiv = document.createElement("div")
        applyStyles(this.buttonDiv, {
            fontFamily: "UbuntuMedium, Arial, Helvetica, sans-serif",
            display: "flex",
            justifyContent: "flex-end",
        })

        const reportSubmitHandler = (event: Event) => {
            event.preventDefault()

            if (this.categoryInput.value === "other" && this.commentsInput.value === "") {
                modalAlert("Please add a description of the abuse being reported.")
                return
            }
            if (this.categoryInput.value === "---") {
                modalAlert("Please choose a category.")
                return
            }

            this.sendReport().then((response) => {
                if (!response.success) {
                    if (response.html !== undefined) {
                        modalAlert(response.html)
                    }
                } else {
                    modalAlert("Your report has been submitted.")
                }
            }).catch((err) => {
                if (err.xhr.status === 403) {
                    modalAlert(err.xhr.responseText)
                } else {
                    modalAlert(`Error sending report (${err})`)
                }
            })
            this.closeReportAbuseRequest.fire(undefined)
        }

        const CancelConfirmButtonPair = ReactComponentRegistry.get("CancelConfirmButtonPair")
        new CancelConfirmButtonPair({
            "cancelClickHandler": () => this.closeReportAbuseRequest.fire(undefined),
            "actionClickHandler": reportSubmitHandler,
            "actionText": i18n.reportCAPS,
            "cancelText": i18n.cancelCAPS,
            "actionColor": "ButtonColor-blue",
            "floatDirection": "ButtonPairFloat-right",
        }, this.buttonDiv)

        form.appendChild(this.buttonDiv)
        this.element.appendChild(form)
    }

    public focusCategory(): void {
        this.categoryInput.focus()
    }

    private parseAbuseReportResult(response: string): ISendReportResponse {
        const p = new ArgJSONMap(response)
        const reportResponse = {
            success: p.getString("result") === "success",
            html: p.getStringOrUndefined("html"),
        } as ISendReportResponse
        p.logUnusedDebugging("parseAbuseReportResult")
        return reportResponse
    }

    private sendReport(): Promise<ISendReportResponse> {
        return new Promise((resolve, reject) => {
            postCb(`abuse/report/${roomName}/`, {
                "category": this.categoryInput.value,
                "additional_comments": this.commentsInput.value,
            }).then((xhr) => {
                resolve(this.parseAbuseReportResult(xhr.responseText))
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

import { isiPad } from "@multimediallc/web-utils/modernizr"
import { currentSiteSettings } from "../../cb/siteSettings"
import { applyStyles } from "../DOMutils"
import { EventRouter } from "../events"
import { fullscreenChange, isFullscreen } from "../fullscreen"
import { BasePlayer } from "../player/basePlayer"
import { JpegPushPlayer } from "../player/jpegPlayer"
import { sdRatio, wsRatio } from "../player/playerSettings"
import { RoomStatusNotifier } from "../roomStatusNotifier"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { TheaterVideoControls } from "./theaterVideoControls"
import { VideoQualityModal } from "./videoQualityModal"
import type { IRoomContext } from "../context"
import type { DraggableCanvas } from "../fullvideolib/draggableCanvas"

const Z_INDEX_BELOW_CONTROLS_ABOVE_ROOM_NOTICE = "2"

export const scrollToTheaterModePlayer = new EventRouter<void>("scrollToTheaterModePlayer")

export class TheaterModePlayer extends BasePlayer {
    // The intended visibility of children from bottom to top:
    // - Player
    // - Room Status Notifications (z-index = 1)
    // - Draggable Canvas (z-index = 2)
    // - Video Controls (z-index = 3)
    // - User Context Menu (different parent -- see Video Panel)
    public videoControls: TheaterVideoControls
    private isWidescreen = true
    public videoQualityModal: VideoQualityModal
    public videoDisabled = false

    constructor(inlineAutoplaySupported: boolean) {
        super(inlineAutoplaySupported, new RoomStatusNotifier(false), { noHook: true })

        const aspectSpacer = document.createElement("div")
        aspectSpacer.style.paddingBottom = `${
            (this.isWidescreen ? wsRatio : sdRatio) * 100
        }%`
        this.element.prepend(aspectSpacer)

        applyStyles(this.element, {
            position: "relative",
            backgroundColor: "#000000",
            overflow: "hidden",
        })

        this.playerComponent.element.style.position = "relative"

        this.adjustWatermarkStyle({
            width: "88px",
            height: "26px",
            top: "6px",
            right: "6px",
            visibility: this.shouldHideWatermark() ? "hidden" : "initial",
            opacity: isiPad() ? "0.5" : undefined,
        })

        this.roomStatusNotifier.moreRoomsLink.style.visibility = "hidden"

        this.videoQualityModal = new VideoQualityModal(this)
        this.videoControls = new TheaterVideoControls(this, this.videoQualityModal)

        this.bindQualityModal()
        this.bindControlsEvents()
        this.afterPlayerCreated()

        this.prependChild(this.roomStatusNotifier)
        this.element.id = "TheaterModePlayer"

        this.addChild(this.videoQualityModal)
        this.addChild(this.videoControls)

        this.videoControls.show()
        this.videoControls.element.dataset["paction"] = "VideoControl"
        this.element.onmousemove = () => {
            this.playerComponent.showControls()
        }

        scrollToTheaterModePlayer.listen(() => {
            this.element.scrollIntoView(false)
            window.scrollBy(0, 32)
        })

        fullscreenChange.listen(() => {
            this.ensureLabelsAppended()
        })
    }

    private ensureLabelsAppended(): void {
        if (isFullscreen()) {
            // On full screen, video labels have to be siblings with videoElement, or else they won't show
            // For players that don't have a videoElement (e.g. JPEG), labels should be appended to them directly
            const labelParent = this.playerComponent.getVideoElement()?.parentElement ?? this.playerComponent.element
            labelParent?.appendChild(this.watermark)
        } else {
            this.element.appendChild(this.watermark)
        }
    }

    public addDraggableCanvas(draggableCanvas: DraggableCanvas): void {
        this.addChild(draggableCanvas)
        this.videoControls.addDraggableCanvas(draggableCanvas)
        // This adjustment to the z-index of the draggable canvas forces the children of the video controls
        // above the children of the draggable Canvas. I do not know why this is the case, since by default
        // the draggable canvas is behind the video controls already and my understanding is:
        //   - div A is above div B
        //   - children of div B will always be under div A
        // It's obviously flawed somehow, but I'm still not sure how.
        draggableCanvas.element.style.zIndex = Z_INDEX_BELOW_CONTROLS_ABOVE_ROOM_NOTICE
    }

    public isProtocolJPEG(): boolean {
        return this.playerComponent instanceof JpegPushPlayer
    }

    private bindControlsEvents(): void {
        this.videoControls.forceHlsPlayerEvent.listen(({ roomContext, unmute }) => {
            this.forceHlsPlayer(roomContext, unmute ?? true)
        })
        this.videoControls.setPlayerComponentMutedEvents.listen((muted) => {
            this.playerComponent.setMuted(muted)
        })
        this.videoControls.playerComponentEnterFullScreenModeEvent.listen(() => {
            this.playerComponent.enterFullScreenMode()
        })
        this.videoControls.setPlayerComponentVolumeMutedEvents.listen((state) => {
            this.playerComponent.setVolumeMuted(
                state.volume,
                state.isMuted,
            )
        })
        this.videoControls.playerComponentReadjustForceHlsOverlayOrderEvent.listen((forceHlsOverlay) => {
            this.playerComponent.element.insertBefore(
                forceHlsOverlay,
                this.playerComponent.element.firstChild,
            )
        })
        this.videoControls.playerComponentReadjustPlayButtonContainerEvent.listen((playButtonContainer) => {
            this.element.insertBefore(playButtonContainer, this.videoControls.element)
        })
        this.videoControls.showJpegPlayerComponentImage.listen(() => {
            if (this.playerComponent instanceof JpegPushPlayer) {
                this.playerComponent.showPlayerImage()
            }
        })
        this.videoControls.requestQualityModalVisibilityChange.listen((visibility) => {
            if (visibility) {
                this.videoQualityModal.show()
            } else {
                this.videoQualityModal.hide()
            }
        })
    }

    protected bindQualityModal(): void {
        this.videoQualityModal.notifyQualityLevelChanged.listen((level) => {
            this.playerComponent.setQualityLevel(level)
        })
        this.videoQualityModal.notifyQualityLevelLabelChanged.listen((levelLabel) => {
            this.videoControls.notifyQualityLevelChanged(levelLabel)
        })
        this.videoQualityModal.requestVideoQualityButtonVisibilityChange.listen((isVisible) => {
            this.videoControls.setVideoQualityButtonVisibility(isVisible)
        })
        this.videoQualityModal.requestVideoQualityModalReposition.listen(() => {
            const playerLeft = this.playerComponent.element.offsetLeft
            const videoQualityButtonLeft = this.videoControls.getQualityButtonLeft()
            const videoControlsHeight = this.videoControls.element.offsetHeight
            this.videoQualityModal.alignToLeftBottom(
                playerLeft + videoQualityButtonLeft,
                videoControlsHeight,
            )
        })
    }

    protected bindVideoEvents(): void {
        super.bindVideoEvents()
        this.playerComponent.requestControlVisibility.listen((visible) => {
            if (visible) {
                this.videoControls.show()
            } else {
                this.videoControls.hide()
            }
        })
        this.playerComponent.setControlVolume?.listen(({ volume, save }) => {
            if (save) {
                this.videoControls.updateAndSaveVolume(volume)
            } else {
                this.videoControls.updateVolume(volume)
            }
        })
        this.playerComponent.setControlIsMuted?.listen(({ isMuted, save }) => {
            if (save) {
                this.videoControls.updateAndSaveIsMuted(isMuted)
            } else {
                this.videoControls.updateIsMuted(isMuted)
            }
        })
        this.playerComponent.videoOfflineChange.listen((offline) => {
            this.videoControls.notifyVideoOfflineChange(offline)
        })
        this.playerComponent.possibleQualityLevelsChanged.listen((levels) => {
            this.videoQualityModal.notifyAvailableQualityLevelsChanged(levels)
        })
    }

    protected stylePlayerComponent(): void {
        this.playerComponent.element.style.position = "absolute"
        const videoPanel = document.getElementById("VideoPanel")
        if(videoPanel instanceof HTMLElement){
            videoPanel.style.height = "100%"
        }
    }

    protected repositionChildren(): void {
        const currentVideoMode = videoModeHandler.getVideoMode()
        const setHeightWidth = () => {
            if (currentVideoMode === VideoMode.Theater) {
                // Do nothing, this is handled in TheaterModeRoomContents.repositionChildren
            } else if (currentVideoMode === VideoMode.VideoOnly) {
                this.videoOnlyResize()
            } else {
                this.defaultResize()
            }
            this.playerComponent.element.style.left = "0"
            this.playerComponent.element.style.top = "0"
            this.adjustWatermarkStyle({ visibility: this.shouldHideWatermark() ? "hidden" : "initial" })
        }

        switch (currentVideoMode) {
            case VideoMode.Split:
                setHeightWidth()
                this.playerComponent.showControls()
                break
            case VideoMode.VideoOnly:
                setHeightWidth()
                this.playerComponent.showControls()
                break
            case VideoMode.Theater:
                setHeightWidth()
                if (!isFullscreen()) {
                    this.playerComponent.showControls()
                }
                break
            case VideoMode.IFS:
                this.playerComponent.showControls()
                break
            case VideoMode.Fullscreen:
                break
            default:
                error(`Unexpected VideoMode: ${currentVideoMode}`)
        }
    }

    private videoOnlyResize(): void {
        const windowHeight = document.documentElement.clientHeight // excludes scrollbars (vs window.innerHeight/Width)
        const windowWidth = document.documentElement.clientWidth
        this.element.style.height = `${this.isWidescreen ? windowHeight : windowWidth * sdRatio}px`
        this.element.style.width = `${this.isWidescreen  ? windowHeight /  wsRatio : windowWidth}px`
        this.playerComponent.element.style.height = "100%"
        this.playerComponent.element.style.width = "100%"
    }

    private defaultResize(): void {
        this.element.style.height = "auto"
        this.element.style.width = "100%"
        this.playerComponent.element.style.height = "100%"
        this.playerComponent.element.style.width = "100%"
    }

    private shouldHideWatermark(): boolean {
        // always hide the watermark on white labels
        return currentSiteSettings.shouldHideWaterMark
    }

    public setIsWidescreen(isWidescreen: boolean): void {
        this.isWidescreen = isWidescreen
        this.repositionChildrenRecursive()
    }

    public getIsWidescreen(): boolean {
        return this.isWidescreen
    }

    public setPlayerComponentDimensions(playerWidth: number, playerHeight: number): void {
        this.playerComponent.element.style.width = `${playerWidth}px`
        this.playerComponent.element.style.height = `${playerHeight}px`
    }

    protected forceJPEGPushPlayer(context: IRoomContext): void {
        super.forceJPEGPushPlayer(context)
        this.videoControls.onForceJPEG()
    }

    protected afterPlayerCreated(): void {
        super.afterPlayerCreated()
        this.videoControls.notifySupportsAutoplayWithAudio(this.playerComponent.supportsAutoplayWithAudio)
        if (this.playerComponent instanceof JpegPushPlayer) {
            this.videoControls.notifyIsJPEG(true)
            this.videoControls.notifyIsHlsPlaceholder(this.playerComponent.getIsHlsPlaceholder())
        } else {
            this.videoControls.notifyIsJPEG(false)
            this.videoControls.notifyIsHlsPlaceholder(false)
        }

        this.ensureLabelsAppended()
    }

    public toggleMute(): void {
        if (this.playerComponent instanceof JpegPushPlayer) {
            // TODO Move context up from videoControls into player.
            const ctx = this.videoControls.getRoomContext()
            if (ctx !== undefined) {
                this.forceHlsPlayer(ctx)
            }
        } else {
            this.videoControls.toggleMuted()
        }
        this.videoControls.show()
    }

    // For some reason webpack can't handle `instanceOf EmbedPlayer` in textVideoControls
    public isEmbed(): boolean {
        return false
    }
}

import { t } from "@lingui/macro"
import { Toggle } from "../../cb/components/toggle"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { Debouncer, DebounceTypes } from "../debouncer"
import { SubSystemType } from "../debug"
import { applyStyles, createHoverText, hoverEvent } from "../DOMutils"
import { documentClickEvent } from "../dropDownComponentBase"
import { EventRouter } from "../events"
import { OverlayComponent } from "../overlayComponent"
import { LLHLSSupported } from "../player/utils"
import { VideoJsPlayer } from "../player/videoJsPlayer"
import type { TheaterModePlayer } from "./theaterModePlayer"
import type { IQualityLevel } from "../player/playerSettings"

export class VideoQualityModal extends OverlayComponent {
    public readonly notifyQualityLevelLabelChanged = new EventRouter<string>("notifyQualityLevelLabelChanged")
    public readonly notifyQualityLevelChanged = new EventRouter<number>("notifyQualityLevelChanged")
    public readonly requestVideoQualityButtonVisibilityChange = new EventRouter<boolean>("requestVideoQualityButtonVisibilityChange")
    public readonly requestVideoQualityModalReposition = new EventRouter<undefined>("requestVideoQualityModalReposition")
    public readonly notifyVisibilityChanged = new EventRouter<boolean>("notifyVisibilityChanged")

    private qualityMap = new Map<string, number>()
    private visibilityTimeoutFunc: number
    private currentQualityLevel: number
    private previousQualityLevel?: number
    private autoOptionDOM: HTMLDivElement | undefined
    private updateAutoQualityOptionLabelDebouncer: Debouncer
    private LLHLSEnabled = false
    private LLHLSSetup = false
    private HLSToggle: Toggle
    private HLSToggleDiv: HTMLDivElement
    private advancedOptions: HTMLDivElement
    private videoQualityLabel: HTMLDivElement
    private LLHLSAllowed = false

    constructor(private player: TheaterModePlayer) {
        super()

        this.element.style.visibility = "hidden"
        applyStyles(this.element, {
            width: "auto",
            minWidth: "200px",
            height: "auto",
            position: "absolute",
            opacity: "0",
            color: "#ffffff",
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            padding: "8px 0px",
            borderRadius: "8px",
            transition: "opacity 100ms ease-in-out, visibility 100ms",
            lineHeight: "16px",
            fontSize: "13px",
            overflow: "visible",
        })

        if (this.player.playerComponent instanceof VideoJsPlayer) {
            addEventListenerPoly("visibilitychange", document, () => {
                if (this.player.videoDisabled) {
                    return
                }
                if (document.visibilityState === "visible") {
                    this.stopQualityTimer()
                } else if (document.visibilityState === "hidden") {
                    this.restartQualityTimer(0)
                }
            })
            const vidPlayer = this.player.playerComponent.getVideoElement()
            if (vidPlayer !== undefined) {
                addEventListenerPoly("resize", vidPlayer, () => {
                    this.updateAutoQualityOptionLabelDebouncer.callFunc()
                })
            }
        }

        // click video quality icon button in video controls bar to show, click anywhere to hide
        documentClickEvent.listen((evt) => {
            if (this.element.style.visibility !== "hidden" &&
                !this.player.videoControls.videoQualityIconButton.element.contains(evt.target as HTMLElement)) {
                this.hide()
            }
        })

        // use debouncer to prevent auto option text from changing during transition
        this.updateAutoQualityOptionLabelDebouncer = new Debouncer(() => this.updateAutoQualityOptionLabel(), {
            debounceType: DebounceTypes.debounce,
            bounceLimitMS: 100,
        })
    }

    protected repositionChildren(): void {
        this.requestVideoQualityModalReposition.fire(undefined)
    }

    public alignToLeftBottom(left: number, bottom: number): void {
        this.element.style.left = `${left}px`
        this.element.style.bottom = `${bottom}px`
    }

    public notifyAvailableQualityLevelsChanged(levels: IQualityLevel[]): void {
        this.qualityMap.clear()
        this.autoOptionDOM = undefined
        this.removeAllDOMChildren()

        if (LLHLSSupported()) {
            this.videoQualityLabel = document.createElement("div")
            this.videoQualityLabel.innerText = t`Video Quality`
            this.videoQualityLabel.style.marginLeft = "12px"
            this.videoQualityLabel.style.fontSize = "10px"
            this.videoQualityLabel.style.lineHeight = "16px"
            this.videoQualityLabel.style.color = "#A7A7A7"
            this.videoQualityLabel.style.display = this.LLHLSAllowed ? "flex" : "none"
            this.element.appendChild(this.videoQualityLabel)
        }

        for (const level of levels) {
            this.qualityMap.set(level.label, level.value)
            if (levels.length === 1) {
                level.toggled = true
            }
            const qualityOptionDOM = this.createQualityOption(level)
            if (level.value === -1) {
                this.autoOptionDOM = qualityOptionDOM
            }
            this.element.appendChild(qualityOptionDOM)
            if (level.toggled) {
                this.notifyQualityLevelLabelChanged.fire(level.label)
                this.currentQualityLevel = level.value
            }
        }
        if (levels.length === 0) {
            this.requestVideoQualityButtonVisibilityChange.fire(false)
        } else {
            this.requestVideoQualityButtonVisibilityChange.fire(true)
        }
        if (LLHLSSupported() && !this.player.playerComponent.shouldDisallowLLHLS()) {
            this.addAdvancedOptions()
            this.addLLHLSToggle()
        }
        this.updateAutoQualityOptionLabelDebouncer.callFunc()
    }

    // eslint-disable-next-line complexity
    private updateAutoQualityOptionLabel() {
        // use debouncer to call this function, don't call directly
        if (this.qualityMap.size === 0 || this.autoOptionDOM === undefined) {
            return
        }
        this.autoOptionDOM.innerText = "auto"

        if (this.currentQualityLevel !== -1) {
            return
        }

        let vidHeight: number | undefined

        // sometimes getVideoElement() returns a video element that doesn't exist on the DOM, so use VJS if possible
        if (this.player.playerComponent instanceof VideoJsPlayer) {
            const videoJs = this.player.playerComponent.getVideoJs()
            if (videoJs !== undefined) {
                vidHeight = videoJs.videoHeight()
            }
        }

        if (vidHeight === undefined) {
            vidHeight = this.player.playerComponent.getVideoElement()?.videoHeight
        }

        if (vidHeight === undefined) {
            return
        }

        this.autoOptionDOM.innerText = `auto (${vidHeight.toString()}p)`
        // sometimes quality includes framerate, so use qualityMap keys instead of vidHeight if possible
        for (const label of this.qualityMap.keys()) {
            if (label.startsWith(vidHeight?.toString())) {
                this.autoOptionDOM.innerText = `auto (${label})`
                break
            }
        }
    }

    private createQualityOption(level: IQualityLevel): HTMLDivElement {
        const div = document.createElement("div")
        div.dataset.testid = "quality-option"
        div.innerText = level.label
        applyStyles(div, {
            padding: "8px 16px",
            cursor: "pointer",
        })
        addEventListenerPoly("mouseenter", div, () => {
            div.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
        })
        addEventListenerPoly("mouseleave", div, () => {
            div.style.backgroundColor = ""
        })
        if (level.toggled) {
            applyStyles(div, { color: "#f47321" })
            addEventListenerPoly("click", div, () => {
                this.hide()
            })
        } else {
            addEventListenerPoly("click", div, () => {
                this.hide()
                this.setQualityLevel(level.label)
                this.notifyQualityLevelLabelChanged.fire(level.label)
            })
        }
        return div
    }

    private setQualityLevel(level: string): void {
        const l = this.qualityMap.get(level)
        if (l === undefined) {
            error("Undefined quality level key", {}, SubSystemType.Video)
            return
        }
        this.currentQualityLevel = l
        // prevent auto option text from changing during transition
        window.setTimeout(() => this.notifyQualityLevelChanged.fire(l), 100)
        this.updateAutoQualityOptionLabelDebouncer.callFunc()
    }

    private restartQualityTimer(level: number): void {
        this.visibilityTimeoutFunc = window.setTimeout(() => {
            this.previousQualityLevel = this.currentQualityLevel
            this.notifyQualityLevelChanged.fire(level)
            this.currentQualityLevel = level
        }, 300000)
    }

    private stopQualityTimer(): void {
        if (this.previousQualityLevel !== undefined) {
            this.notifyQualityLevelChanged.fire(this.previousQualityLevel)
            this.previousQualityLevel = undefined
        }
        clearTimeout(this.visibilityTimeoutFunc)
    }

    public show(): void {
        this.notifyVisibilityChanged.fire(true)
        this.element.style.visibility = ""
        this.element.style.opacity = "1"
        // keep control bar visible when this modal is visible
        this.player.videoControls.show()
        this.repositionChildren()
    }

    public hide(): void {
        this.notifyVisibilityChanged.fire(false)
        this.element.style.opacity = "0"
        this.element.style.visibility = "hidden"
    }

    private addAdvancedOptions(): void {
        this.advancedOptions = document.createElement("div")
        this.advancedOptions.style.display = this.LLHLSAllowed ? "block" : "none"

        const divider = document.createElement("hr")
        divider.style.borderTop = "1px solid #bbb"
        divider.style.margin = "0"
        divider.style.opacity = "0.3"
        divider.style.display = "block"

        const advancedLabel = document.createElement("div")
        advancedLabel.innerText = t`Advanced`
        advancedLabel.style.marginTop = "8px"
        advancedLabel.style.marginLeft = "12px"
        advancedLabel.style.fontSize = "10px"
        advancedLabel.style.lineHeight = "16px"
        advancedLabel.style.color = "#A7A7A7"

        this.advancedOptions.appendChild(divider)
        this.advancedOptions.appendChild(advancedLabel)
        
        this.element.appendChild(this.advancedOptions)
    }

    private addLLHLSToggle(): void {
        this.HLSToggleDiv = document.createElement("div")
        
        const infoDiv = document.createElement("div")
        const label = document.createElement("label")
        label.innerText = t`Minimize Delay`
        label.style.lineHeight = "16px"
        const infoIcon = document.createElement("img")
        infoIcon.src = `${STATIC_URL_ROOT}images/info-icon-white.svg`

        infoDiv.appendChild(label)
        infoDiv.appendChild(infoIcon)

        const infoToolTip = createHoverText()
        infoToolTip.textContent = t`Enable to reduce the delay between you and the creator. If you are having video issues, try turning this setting on/off.`

        hoverEvent(infoIcon).listen((hovering: boolean) => {
            infoToolTip.style.display = hovering ? "block" : "none"
            infoToolTip.style.opacity = hovering ? "1" : "0"
            infoIcon.style.opacity = hovering ? "1" : "0.8"
        })

        applyStyles(infoDiv, {
            display: "flex",
            gap: "4px",
        })
        applyStyles(label, {
            color: "#ffffff",
            fontSize: "13px",
            marginRight: "5px",
            cursor: "pointer",
        })
        applyStyles(this.HLSToggleDiv, {
            display: this.LLHLSAllowed ? "flex" : "none",
            padding: "8px 8px 8px 16px",
            marginRight: "",
            cursor: "pointer",
            gap: "48px",
            height: "16px",
        })
        applyStyles(infoIcon, {
            height: "16px",
            width: "16px",
            cursor: "pointer",
            opacity: "0.8",
        })
        applyStyles(infoToolTip, {
            width: "222px",
            height: "auto",
            padding: "8px 16px",
            bottom: "40px",
            left: "-10px",
        })

        this.HLSToggleDiv.appendChild(infoDiv)

        this.HLSToggleDiv.appendChild(infoToolTip)

        this.HLSToggle = new Toggle(this.LLHLSEnabled, () => {
            this.player.playerComponent.forceStream(this.HLSToggle.isChecked())
        }, { height: 16, width: 32 })
        this.HLSToggle.element.style.marginBottom = "2px"
        this.HLSToggleDiv.appendChild(this.HLSToggle.element)

        this.element.appendChild(this.HLSToggleDiv)

        this.setupLLHLSEvents()
    }

    private setupLLHLSEvents(): void {
        this.HLSToggleDiv.onclick = () => {
            this.HLSToggle.setChecked(!this.HLSToggle.isChecked())
            this.LLHLSEnabled = this.HLSToggle.isChecked()
        }
        this.HLSToggleDiv.onmouseenter = () => {
            this.HLSToggleDiv.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
        }
        this.HLSToggleDiv.onmouseleave = () => {
            this.HLSToggleDiv.style.backgroundColor = ""
        }
        if (this.LLHLSSetup){
            return
        }
        this.LLHLSSetup = true
        this.player.playerComponent.updateLLHLSButton.listen(({ allowed, enabled }) => {
            this.LLHLSAllowed = allowed
            if (allowed) {
                this.videoQualityLabel.style.display = "block"
                this.HLSToggleDiv.style.display = "flex"
                this.advancedOptions.style.display = "block"
            } else {
                this.videoQualityLabel.style.display = "none"
                this.HLSToggleDiv.style.display = "none"
                this.advancedOptions.style.display = "none"
            }
            if (this.LLHLSEnabled !== enabled) {
                this.HLSToggle.setCheckedDirectly(enabled)
                this.LLHLSEnabled = enabled
            }
        })
    }
}
